import { markRaw } from "vue";
import type { Raw, Component } from "vue";

import Invoices from "./modules/Invoices/Invoices.vue";
import News from "./modules/News/News.vue";
import Puk from "./modules/Puk/Puk.vue";
import Orders from "./modules/Orders/Orders.vue";
import OrderNowNFA from "./modules/OrderNowNFA/OrderNowNFA.vue";
import OrderNowMFARAM from "./modules/OrderNowMFARAM/OrderNowMFARAM.vue";
import MyApplications from "./modules/MyApplications/MyApplications.vue";
import Errands from "./modules/Errands/Errands.vue";
import SystemDisturbances from "./modules/SystemDisturbances/SystemDisturbances.vue";
import StatisticsAndReports from "./modules/StatisticsAndReports/StatisticsAndReports.vue";
import TouchPointPlus from "./modules/TouchPointPlus/TouchPointPlus.vue";
import DataNet from "./modules/DataNet/DataNet.vue";
import Recommendations from "./modules/Recommendations/Recommendations.vue";
import MyBenefits from "./modules/MyBenefits/MyBenefits.vue";
import ReleaseNote from "./modules/ReleaseNote/ReleaseNote.vue";

import {
  recommendationsEnabled,
  myBenefitsEnabled,
  dataNetEnabled,
} from "../../helpers/featureToggles";

export interface Module {
  id: string;
  name: string;
  title: {
    en: { MODULE_TITLE: string };
    sv: { MODULE_TITLE: string };
  };
  icon: string;
  component: Raw<Component>;
  featureEnabled: boolean;
  requireElevatedUser?: boolean;
}

const modules: Module[] = [
  {
    id: "Orders",
    name: "ORDERS",
    title: {
      en: { MODULE_TITLE: "Ongoing orders" },
      sv: { MODULE_TITLE: "Pågående beställningar" },
    },
    icon: "delivery",
    component: markRaw(Orders),
    featureEnabled: true,
  },
  {
    id: "OrderNowNFA",
    name: "ORDER_NOW_NFA",
    title: {
      en: { MODULE_TITLE: "Order mobile subscription" },
      sv: { MODULE_TITLE: "Beställ mobilabonnemang" },
    },
    icon: "cart",
    component: markRaw(OrderNowNFA),
    requireElevatedUser: true,
    featureEnabled: true,
  },
  {
    id: "OrderNowMFARAM",
    name: "ORDER_NOW_MFA_RAM",
    title: {
      en: { MODULE_TITLE: "Order mobile subscription" },
      sv: { MODULE_TITLE: "Beställ mobilabonnemang" },
    },
    icon: "cart",
    component: markRaw(OrderNowMFARAM),
    requireElevatedUser: true,
    pui: "",
    featureEnabled: true,
  },
  {
    id: "StatisticsAndReports",
    name: "STATISTICS_AND_REPORTS",
    title: {
      en: { MODULE_TITLE: "Statistics and reports" },
      sv: { MODULE_TITLE: "Statistik och rapporter" },
    },
    icon: "bar-chart",
    component: markRaw(StatisticsAndReports),
    featureEnabled: true,
  },
  {
    id: "Puk",
    name: "PUK",
    title: {
      en: { MODULE_TITLE: "Search PUK-code" },
      sv: { MODULE_TITLE: "Sök PUK-kod" },
    },
    icon: "lock-closed",
    component: markRaw(Puk),
    requireElevatedUser: true,
    featureEnabled: true,
  },
  {
    id: "MyApplications",
    name: "MY_APPLICATIONS",
    title: {
      en: { MODULE_TITLE: "My applications" },
      sv: { MODULE_TITLE: "Mina applikationer" },
    },
    icon: "services",
    component: markRaw(MyApplications),
    featureEnabled: true,
  },
  {
    id: "Errands",
    name: "ERRANDS",
    title: {
      en: { MODULE_TITLE: "Cases and questions" },
      sv: { MODULE_TITLE: "Ärenden och frågor" },
    },
    icon: "support",
    component: markRaw(Errands),
    featureEnabled: true,
  },
  {
    id: "SystemDisturbances",
    name: "SYSTEM_DISTURBANCES",
    title: {
      en: { MODULE_TITLE: "Operation status systems" },
      sv: { MODULE_TITLE: "Driftstatus system" },
    },
    icon: "server",
    component: markRaw(SystemDisturbances),
    featureEnabled: true,
  },
  {
    id: "Invoices",
    name: "INVOICES",
    title: {
      en: { MODULE_TITLE: "Invoices" },
      sv: { MODULE_TITLE: "Fakturor" },
    },
    icon: "invoice",
    component: markRaw(Invoices),
    featureEnabled: true,
  },
  {
    id: "News",
    name: "NEWS",
    title: {
      en: { MODULE_TITLE: "News (not impl.)" },
      sv: { MODULE_TITLE: "Nyheter (ej impl.)" },
    },
    icon: "news",
    component: markRaw(News),
    featureEnabled: false,
  },
  {
    id: "TouchPointPlus",
    name: "TOUCH_POINT_PLUS",
    title: {
      en: { MODULE_TITLE: "Touchpoint Plus aftermarket" },
      sv: { MODULE_TITLE: "Touchpoint Plus eftermarknad" },
    },
    icon: "switch",
    component: markRaw(TouchPointPlus),
    featureEnabled: true,
  },
  {
    id: "DataNet",
    name: "DATA_NET",
    title: {
      en: { MODULE_TITLE: "DataNet" },
      sv: { MODULE_TITLE: "DataNet" },
    },
    icon: "dsl-hub",
    component: markRaw(DataNet),
    pui: "ORDER_DATANET",
    featureEnabled: dataNetEnabled(),
  },
  {
    id: "Recommendations",
    name: "RECOMMENDATIONS",
    title: {
      en: { MODULE_TITLE: "Recommendations" },
      sv: { MODULE_TITLE: "Rekommendationer" },
    },
    icon: "offering",
    component: markRaw(Recommendations),
    featureEnabled: recommendationsEnabled(),
  },
  {
    id: "BusinessPlus",
    name: "MY_BENEFITS",
    title: {
      en: { MODULE_TITLE: "My benefits" },
      sv: { MODULE_TITLE: "Mina förmåner" },
    },
    icon: "offering",
    component: markRaw(MyBenefits),
    featureEnabled: myBenefitsEnabled(),
  },
  {
    id: "ReleaseNotes",
    name: "RELEASE_NOTE",
    title: {
      en: { MODULE_TITLE: "What's new in MyBusiness" },
      sv: { MODULE_TITLE: "Nytt i MyBusiness" },
    },
    icon: "offering",
    component: markRaw(ReleaseNote),
    featureEnabled: true,
  },
].filter((module) => module.featureEnabled);

export const getAllModuleIds = (): string[] => modules.map((module) => module.id);
export const getModuleById = (id: string): Module | undefined =>
  modules.find((module) => module.id === id);

export default modules;
