import { supportCorpCase } from "@telia/b2x-rest-client";
import { CorpCaseService } from "@telia/b2x-rest-client/types/generated/services/support-corp-case";
export type { CasesWidgetResponse } from "@telia/b2x-rest-client/types/generated/services/support-corp-case";

type CaseListRequestSettings = Parameters<typeof CorpCaseService.getCasesForWidget>[0];
type SortBy = NonNullable<CaseListRequestSettings["sortBy"]>;
type SortDirection = NonNullable<CaseListRequestSettings["sortDirection"]>;
type Statuses = NonNullable<CaseListRequestSettings["statuses"]>;

export const getErrands = (
  xAuthScopeId: string,
  organizationTscIds: string[],
  size = 5,
  sortBy: SortBy = "UPDATED_ON",
  sortDirection: SortDirection = "DESC",
  statuses: Statuses = ["NEW", "OPEN", "AWAITING_INFO", "RESOLVED"]
): ReturnType<typeof supportCorpCase.CorpCaseService.getCasesForWidget> => {
  return supportCorpCase.CorpCaseService.getCasesForWidget({
    xAuthScopeId,
    organizationTscIds,
    size,
    sortBy,
    sortDirection,
    statuses,
  });
};
