import sv from "./sv.json";
import en from "./en.json";
import { addTranslation, addTranslations, vueTranslateMixin, currentLocale } from "@telia/b2b-i18n";
import { fetchMicrocopy } from "@telia/b2x-microcopy";

export const translateMixin = vueTranslateMixin;
export const translateSetup = async (modulesList?, identifiers?) => {
  modulesList?.forEach((module) => {
    sv.MODULES = { ...sv.MODULES, [module.name]: module.title.sv.MODULE_TITLE };
    en.MODULES = { ...en.MODULES, [module.name]: module.title.en.MODULE_TITLE };
  });
  addTranslations({ sv, en });

  if (identifiers && identifiers.length > 0) {
    const locale = currentLocale();
    const translations = await fetchMicrocopy(locale, identifiers);
    addTranslation(translations, locale);
  }
};

export const translateNewModules = (newModules) => {
  newModules?.forEach((module) => {
    sv.NEW_MODULES = { ...sv.NEW_MODULES, [module.name]: module.title.sv.MODULE_TITLE };
    en.NEW_MODULES = { ...en.NEW_MODULES, [module.name]: module.title.en.MODULE_TITLE };
  });
  addTranslations({ sv, en });
};
