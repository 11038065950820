<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { useDashboard } from "../../composables/dashboard";

import { translateSetup, translateMixin } from "./locales";

import ModuleEditPanel from "./components/ModuleEditPanel.vue";
import { trackNavigationInteraction } from "../../utils/trackGA4";

type Props = {
  id: string;
  title: string;
  subtitle?: string;
  linkTitle?: string;
  linkHref?: string;
  paddedContent?: boolean;
  moduleStyle?: string;
  footerDisabled?: boolean;
  showOrganisationWarning?: boolean;
  loading?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  title: "",
  paddedContent: true,
  moduleStyle: "",
  footerDisabled: false,
  showOrganisationWarning: false,
  loading: false,
});

onBeforeMount(async () => {
  await translateSetup();
});

const t = translateMixin.methods.t;

const { draggable } = useDashboard();

const linkWidth = computed(() => {
  return `${props.linkTitle?.length}ch`;
});

const trackEventModule = (linkTitle: string) => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    `${props.id}_Visa_Alla klickad`
  );

  trackNavigationInteraction(linkTitle, "click");
};
</script>

<template>
  <article
    class="module"
    :class="[
      {
        'module--padded': paddedContent || showOrganisationWarning,
      },
      moduleStyle,
    ]"
    data-mybd-test-id="module"
    :data-id="id"
    :key="id"
  >
    <header
      class="module__header"
      :class="{ 'module__header--draggable': draggable && !loading }"
      v-if="title"
      t-id="module-head"
    >
      <div class="module__header-top">
        <telia-heading variant="title-100" tag="h3" class="module__title">
          {{ title }}
        </telia-heading>
        <ModuleEditPanel @remove-module="$emit('module-removed', id)" :title="title">
        </ModuleEditPanel>
      </div>
      <div class="module__header-bottom" v-if="subtitle">
        <telia-p variant="additional-100" class="module__subtitle" v-text="subtitle"></telia-p>
      </div>
    </header>

    <div class="module__body" t-id="module-body">
      <slot v-if="!showOrganisationWarning" />
      <telia-notification
        v-if="showOrganisationWarning"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("MODULE.ORGANISATION_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("MODULE.ORGANISATION_ERROR.TITLE") }}
        </span>
      </telia-notification>
    </div>

    <footer v-if="linkHref && linkTitle" class="module__footer" t-id="module-footer">
      <telia-link
        v-if="!footerDisabled"
        :href="linkHref"
        @click="trackEventModule(linkTitle)"
        variant="navigation"
        class="module__footer-link"
        disable-visited
      >
        {{ linkTitle }}
      </telia-link>
      <telia-skeleton
        v-else-if="loading"
        :style="{ width: linkWidth }"
        class="module__skeleton-footer"
      ></telia-skeleton>
      <div v-else class="module__footer-placeholder"></div>
    </footer>
  </article>
</template>

<style lang="scss" src="./styles/module.scss"></style>
