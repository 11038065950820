<template>
  <div class="skeleton-cards__wrapper">
    <telia-skeleton
      v-for="index in numberOfCards"
      :key="index"
      class="skeleton-cards__card"
    ></telia-skeleton>
  </div>
</template>

<script>
export default {
  name: "SkeletonCards",
  props: {
    numberOfCards: { type: Number, required: true },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/voca";

.skeleton-cards {
  &__wrapper {
    margin: $telia-spacing-24 $telia-spacing-24;
  }

  &__card {
    width: 100%;
    height: $telia-spacing-96;
    padding-bottom: $telia-spacing-16;
  }
}
</style>
