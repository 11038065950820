<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";

import Module from "../../Module.vue";

import { translateSetup, translateMixin } from "./locales";

import LinkCard from "../../../shared/LinkCard.vue";

import STATE from "../../state.enum";
import { trackNavigationInteraction } from "../../../../utils/trackGA4";

const TPP_AFTERMARKET_BASE_URL =
  "/foretag/mybusiness/:scopeId/bestall/touchpoint-plus-eftermarknad";

onBeforeMount(() => {
  translateSetup(props.title, props.name);
});

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const t = translateMixin.methods.t;

const tppAftermarketOrderTypes = ref({
  ADD_USER: "lagg-till-anvandare",
  CHANGE_USER: "andra-anvandare",
  REMOVE_USER: "ta-bort-anvandare",
});

const generateOrderLink = (tppAftermarketOrderType: string) => {
  return `${TPP_AFTERMARKET_BASE_URL.replace(
    ":scopeId",
    props.scopeId ? props.scopeId : ""
  ).replace("//", "/")}/${tppAftermarketOrderType}`;
};

const trackEventLinkCardClicked = (tppAftermarketOrderType: string) => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    `TPP ${tppAftermarketOrderType} klickad`
  );
};
</script>

<template>
  <Module
    id="TouchPointPlus"
    :title="t('TOUCH_POINT_PLUS.MODULE_TITLE')"
    @module-removed="emit('module-removed', $event)"
  >
    <div data-dont-collect ref="moduleContainer">
      <LinkCard
        data-testid="add-user-link"
        :title="t('TOUCH_POINT_PLUS.ADD_USER')"
        icon="add"
        :href="generateOrderLink(tppAftermarketOrderTypes.ADD_USER)"
        @click="trackEventLinkCardClicked(tppAftermarketOrderTypes.ADD_USER)"
      />
      <LinkCard
        data-testid="change-user-link"
        :title="t('TOUCH_POINT_PLUS.CHANGE_USER')"
        icon="settings"
        :href="generateOrderLink(tppAftermarketOrderTypes.CHANGE_USER)"
        @click="trackEventLinkCardClicked(tppAftermarketOrderTypes.CHANGE_USER)"
      />
      <LinkCard
        data-testid="remove-user-link"
        :title="t('TOUCH_POINT_PLUS.REMOVE_USER')"
        icon="close"
        :href="generateOrderLink(tppAftermarketOrderTypes.REMOVE_USER)"
        @click="trackEventLinkCardClicked(tppAftermarketOrderTypes.REMOVE_USER)"
      />
    </div>
  </Module>
</template>
