<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";

import { logError } from "@telia/b2x-logging";
import { currentLanguage } from "@telia/b2b-i18n";
import type {
  ApiError,
  DisturbanceServices,
} from "@telia/b2x-support-rest-client/dist/support-corp-csm-disturbance";

import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import { translateSetup, translateMixin } from "./locales";
import { getSystemDisturbances } from "./disturbances.api";

import STATE from "../../state.enum";

onBeforeMount(() => {
  translateSetup(props.title, props.name);
  updateDisturbances();
});

const t = translateMixin.methods.t;

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const skeletonSystemsCount = ref(3);
const systems = ref<DisturbanceServices[]>([]);

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  moduleLoader,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

const { organisations, selectedOrganisation } = useOrganisations();

const shouldDisableFooter = computed(() => {
  return status.value === STATE.LOADING || status.value === STATE.ERROR;
});

const updateDisturbances = async () => {
  if (!selectedOrganisation.value) {
    status.value = STATE.PRECONDITION_NOT_MET;
    return;
  }

  status.value = STATE.LOADING;
  let organizationNumber = getOrganisationNumberFromTscid(selectedOrganisation.value);

  try {
    const response = await getSystemDisturbances(
      props.scopeId,
      organizationNumber,
      currentLanguage()
    );

    systems.value = response.disturbanceServicesList ?? [];
    status.value = systems.value.length ? STATE.SUCCESS : STATE.NO_ITEMS;
    skeletonSystemsCount.value = Math.max(systems.value.length, 1);
  } catch (error) {
    if ((error as ApiError).hasOwnProperty("status")) {
      logError(
        "b2b-dashboard",
        `Failed to fetch system disturbances in the System disturbances module - ${
          (error as ApiError).status
        }`
      );
    } else {
      // Currently around 70% of the errors that get logged don't contain status.
      // Want to see if I can break that down a bit more.
      logError(
        "b2b-dashboard",
        `Failed to fetch system disturbances in the System disturbances module - ${
          (error as any).message
        }`
      );
    }

    systems.value = [];
    status.value = STATE.ERROR;
  } finally {
    await refreshTheLayout();
  }
};

const getOrganisationNumberFromTscid = (tscid: string) => {
  return organisations.value.find((org) => org.tscid === tscid)?.organizationNumber ?? "";
};

const getStatusText = (state: string | undefined) => {
  switch (state) {
    case "0":
      return t("SYSTEM_DISTURBANCES.SYSTEM_HAS_NO_DISTURBANCE");
    case "1":
    case "2":
    case "5":
      return t("SYSTEM_DISTURBANCES.SYSTEM_HAS_DISTURBANCE");
    default:
      return t("SYSTEM_DISTURBANCES.SYSTEM_HAS_INFORMATION");
  }
};

const getStatusVariant = (state: string | undefined) => {
  switch (state) {
    case "0":
      return "positive";
    case "1":
    case "5":
      return "warning";
    case "2":
      return "caution";
    default:
      return "information";
  }
};

watch(() => selectedOrganisation.value, updateDisturbances);
</script>

<template>
  <Module
    id="SystemDisturbances"
    :title="t('SYSTEM_DISTURBANCES.MODULE_TITLE')"
    :link-title="t('SYSTEM_DISTURBANCES.LINK_ALL_DISTURBANCES')"
    link-href="/foretag/mybusiness/support/driftstatus"
    :paddedContent="status === STATE.ERROR"
    :footerDisabled="shouldDisableFooter"
    @module-removed="emit('module-removed', $event)"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :loading="isLoading"
  >
    <div class="disturbances__container" ref="moduleContainer">
      <div v-if="isLoading" ref="moduleLoader" key="moduleLoader">
        <div
          v-for="index in skeletonSystemsCount"
          :key="index"
          class="system-disturbances__skeleton-wrapper"
        >
          <telia-skeleton
            class="system-disturbances__skeleton system-disturbances__skeleton--title"
          ></telia-skeleton>
          <telia-skeleton
            class="system-disturbances__skeleton system-disturbances__skeleton--status"
          ></telia-skeleton>
        </div>
      </div>

      <div v-if="status === STATE.SUCCESS" class="disturbances__list">
        <div v-for="system in systems" class="disturbances__list-row" :key="system.service">
          <div class="disturbances__list-system">
            <telia-p class="disturbances__system-name" :title="system.service">
              <strong>
                {{ system.service }}
              </strong>
            </telia-p>
            <telia-status-badge :variant="getStatusVariant(system.state)">
              {{ getStatusText(system.state) }}
            </telia-status-badge>
          </div>
        </div>
      </div>

      <module-empty-state v-if="status === STATE.NO_ITEMS">
        {{ t("SYSTEM_DISTURBANCES.NO_ITEMS") }}
      </module-empty-state>

      <telia-notification
        v-if="status === STATE.ERROR"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("SYSTEM_DISTURBANCES.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("SYSTEM_DISTURBANCES.ERROR.TITLE") }}
        </span>
      </telia-notification>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/borders/mixins";
@import "@teliads/components/foundations/breakpoints/mixins";

@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/typography/variables";

.disturbances__list {
  margin: 0 $telia-spacing-16;
  @include telia-color("black");

  &-row {
    width: 100%;
    padding: $telia-spacing-12 0;
    &:not(:last-child) {
      @include telia-border("gray-200", "xs", "bottom");
    }
  }

  .disturbances__list-system {
    display: grid;
    gap: $telia-spacing-8;
    grid-template-columns: 1fr;

    @include telia-breakpoint(lg) {
      grid-template-columns: auto 1fr;
    }
  }

  telia-status-badge {
    @include telia-breakpoint(lg) {
      justify-self: right;
    }
  }

  .disturbances__system-name {
    margin-bottom: 0;

    @include telia-breakpoint(lg) {
      line-height: calc(#{$telia-spacing-24} + 0.1rem);
    }
  }

  .disturbances__status {
    font-size: $telia-spacing-12;
    line-height: $telia-spacing-16;
  }
}

.system-disturbances {
  &__skeleton-wrapper {
    padding: $telia-spacing-12 0;
    margin: 0 $telia-spacing-16;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:not(:last-child) {
      @include telia-border("gray-200", "xs", "bottom");
    }
  }

  &__skeleton {
    height: $telia-spacing-24;

    &--title {
      width: 50%;
    }

    &--status {
      width: 40%;
    }
  }
}
</style>
