<script setup lang="ts">
import { onBeforeMount } from "vue";

import { useScope } from "../../../../composables/scope";
import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import { translateSetup, translateMixin } from "./locales";

import STATE from "../../state.enum";

import AddressMaster from "./components/AddressMaster.vue";

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const t = translateMixin.methods.t;

onBeforeMount(() => {
  translateSetup(props.title, props.name);
});

const { refreshTheLayout, moduleContainer, Module } = useModule(props, emit);
const { selectedOrganisation } = useOrganisations();

const { scopeId } = useScope();

const handleAddressSubmit = (event) => {
  const streetName = event.streetName;
  const streetNumber = event.streetNumber;
  const postalCode = event.postalCode;
  const city = event.city;
  if (streetName && streetNumber && postalCode && city) {
    window.location.href = `https://www.telia.se/foretag/mybusiness/${scopeId.value}/bestall/datanet/oc?tscid=${selectedOrganisation.value}&city=${city}&streetName=${streetName}&streetNumber=${streetNumber}&postalCode=${postalCode}`;
  }
};

const handleCoordinateSubmit = (coordinates) => {
  if (!coordinates.x && !coordinates.y) return;
  window.location.href = `https://www.telia.se/foretag/mybusiness/${scopeId.value}/bestall/datanet/oc?tscid=${selectedOrganisation.value}&x=${coordinates.x}&y=${coordinates.y}`;
};
</script>

<template>
  <Module
    id="DataNet"
    :title="t('DATA_NET.MODULE_TITLE')"
    :paddedContent="false"
    @module-removed="emit('module-removed', $event)"
  >
    <div ref="moduleContainer">
      <AddressMaster
        :scopeId="scopeId"
        @addressSubmit="handleAddressSubmit($event)"
        @coordinateSubmit="handleCoordinateSubmit($event)"
        @refreshTheLayout="refreshTheLayout()"
      />
    </div>
  </Module>
</template>

<style lang="scss" scoped>
/* Needed to show input and tooltip above other modules */
article {
  z-index: 10 !important;
}
</style>
