<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";

import { logError } from "@telia/b2x-logging";
import { corpProductOrder } from "@telia/b2b-rest-client";
import { ApiError, WidgetProductBundleUI } from "@telia/b2b-rest-client/dist/corp-product-order";

import { useScope } from "../../../../composables/scope";
import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import { wait } from "../../../../helpers";

import ProductsList from "./ProductsList.vue";
import { translateSetup, translateMixin } from "./locales";

import SkeletonTabs from "../../../shared/SkeletonLoaders/SkeletonTabs.vue";
import SkeletonCards from "../../../shared/SkeletonLoaders/SkeletonCards.vue";

import STATE from "../../state.enum";

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

type ProductCategories = {
  name: string;
  products: {
    id: string;
    category: string;
    productCode: string;
    name: string;
    recurringFee: string;
  }[];
};

const productCategories = ref<ProductCategories[]>([]);
const t = translateMixin.methods.t;

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
  getProducts();
});

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

const { selectedOrganisation } = useOrganisations();

const { scopeId } = useScope();

const disableFooter = computed(() => {
  if (status.value === STATE.BAD_AGREEMENT) return false;
  return status.value !== STATE.SUCCESS;
});

const moduleLink = computed(() => {
  if (status.value === STATE.BAD_AGREEMENT) {
    return `/foretag/mybusiness/${scopeId.value}/bestall/oppna-mobilt-abonnemang/${selectedOrganisation.value}`;
  }

  return `/foretag/mybusiness/${scopeId.value}/bestall/${selectedOrganisation.value}/mobilabonnemang`;
});

const paddedContent = computed(() => {
  return [STATE.SERVER_ERROR, STATE.ERROR, STATE.NO_AGREEMENT, STATE.BAD_AGREEMENT].includes(
    status.value
  );
});

const onTabChange = async () => {
  await wait(10);

  await refreshTheLayout();
};

const getProducts = async () => {
  try {
    if (!hasSelectedOrganisation) {
      status.value = STATE.PRECONDITION_NOT_MET;
      return;
    }

    status.value = STATE.LOADING;

    const response = await corpProductOrder.WidgetProductOrderControllerService.getWidgetProducts(
      scopeId.value,
      selectedOrganisation.value
    );

    const mappedResponse = mapResponse(response);

    productCategories.value = filterProductCategories(mappedResponse);
    status.value = productCategories.value.length ? STATE.SUCCESS : STATE.NO_ITEMS;
  } catch (error) {
    let badAgreement = false;
    let noAgreement = false;
    let serverError = false;

    if (isApiError(error)) {
      badAgreement = error.body?.translationKey === STATE.BAD_AGREEMENT;
      noAgreement = error.body?.translationKey === STATE.NO_AGREEMENT;
      serverError = error.body?.status >= 500;
    }

    logError("b2b-dashboard", "Failed to get NFA customers products in the OrderNowNFA module");

    status.value = (() => {
      if (noAgreement) return STATE.NO_AGREEMENT;
      if (badAgreement) return STATE.BAD_AGREEMENT;
      if (serverError) return STATE.SERVER_ERROR;
      return STATE.ERROR;
    })();

    productCategories.value = [];
  } finally {
    await refreshTheLayout();
  }
};

const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).body !== undefined;
};

const mapResponse = (productCategories: WidgetProductBundleUI[]): ProductCategories[] => {
  return productCategories.map((category) => ({
    name: category.name ?? "",
    products:
      category.products?.map((product) => ({
        id: product.id ?? "",
        category: product.category ?? "",
        name: product.name ?? "",
        recurringFee: product.recurringFee ?? "",
        productCode: product.productCode ?? "",
      })) ?? [],
  }));
};

const filterProductCategories = (productCategories: ProductCategories[]) => {
  return productCategories.reduce((acc: ProductCategories[], category) => {
    if (
      category.products?.length &&
      (category.name === "Jobbmobil" || category.name === "Mobilt Bredband")
    ) {
      acc.push({
        name: category.name,
        products: category.products.slice(0, 2),
      });
    }

    return acc;
  }, []);
};

watch(() => selectedOrganisation.value, getProducts);
</script>

<template>
  <Module
    id="OrderNowNFA"
    :title="t('ORDER_NOW_NFA.MODULE_TITLE')"
    :link-title="t('ORDER_NOW_NFA.LINK_ALL_PRODUCTS')"
    :link-href="moduleLink"
    @module-removed="emit('module-removed', $event)"
    :footerDisabled="disableFooter"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :paddedContent="paddedContent"
    :loading="isLoading"
  >
    <div class="orders-container" ref="moduleContainer">
      <div v-if="isLoading" class="order-now-nfa__skeleton-wrapper">
        <skeleton-tabs></skeleton-tabs>
        <skeleton-cards :numberOfCards="2"></skeleton-cards>
      </div>

      <telia-tab
        t-id="tabs"
        variant="light-wide"
        v-if="status === STATE.SUCCESS && productCategories.length > 1"
        @vocaTabChange="onTabChange"
      >
        <telia-tab-content
          :t-id="`category-tab-${category.name}`"
          :key="category.name"
          v-for="category in productCategories"
          :name="category.name"
        >
          <ProductsList
            :products="category.products"
            :selected-organisation="selectedOrganisation"
            :scope-id="scopeId"
            inTab
          />
        </telia-tab-content>
      </telia-tab>

      <ProductsList
        v-if="status === STATE.SUCCESS && productCategories.length === 1"
        :products="productCategories[0].products"
        :selected-organisation="selectedOrganisation"
        :scope-id="scopeId"
      />

      <telia-notification
        v-if="status === STATE.SERVER_ERROR"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_NFA.SERVER_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_NFA.SERVER_ERROR.TITLE") }}
        </span>
      </telia-notification>

      <telia-notification
        v-if="status === STATE.ERROR"
        t-id="general-error"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_NFA.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_NFA.ERROR.TITLE") }}
        </span>
      </telia-notification>

      <telia-notification
        v-if="status === STATE.NO_AGREEMENT"
        t-id="no-agreement"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_NFA.NO_AGREEMENT_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_NFA.NO_AGREEMENT_ERROR.TITLE") }}
        </span>
      </telia-notification>

      <telia-notification
        v-if="status === STATE.BAD_AGREEMENT"
        t-id="general-error"
        heading-tag="h4"
        variant="inline"
        status="information"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_NFA.BAD_AGREEMENT_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_NFA.BAD_AGREEMENT_ERROR.TITLE") }}
        </span>
        <span slot="content">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_NFA.BAD_AGREEMENT_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_NFA.BAD_AGREEMENT_ERROR.CONTENT") }}
        </span>
      </telia-notification>

      <module-empty-state v-if="status === STATE.NO_ITEMS" t-id="no-products">
        {{ t("ORDER_NOW_NFA.NO_ITEMS") }}
      </module-empty-state>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.orders-container {
  position: relative;
  min-height: 7.2rem;
}
</style>
