<script setup lang="ts">
import { computed, nextTick, useSlots } from "vue";
import { trackNavigationInteraction } from "../../utils/trackGA4";

const props = defineProps<{
  href?: string;
  icon?: string;
  target?: string;
  title: string;
  rightTitle?: string;
  gaText?: string;
}>();

const emit = defineEmits<{
  (e: "click", event: Event): void;
}>();

const slots = useSlots();

const hasBody = computed(() => {
  return !!slots.default;
});

const hasLeftSlot = computed(() => {
  return !!slots.left;
});

const linkRel = computed(() => {
  return props.target === "_blank" ? "noopener noreferrer" : "";
});

const onClick = async (event: Event) => {
  trackNavigationInteraction(props.gaText ? props.gaText : props.title, "click");
  await nextTick();
  emit("click", event);
};
</script>

<template>
  <a
    class="list-item"
    :class="{
      'list-item--icon': icon,
      'list-item--left-slot': hasLeftSlot,
    }"
    :href="href"
    :target="target"
    :rel="linkRel"
    @click="onClick"
  >
    <div v-if="hasLeftSlot" class="list-item__left">
      <slot name="left" />
    </div>

    <telia-icon v-if="icon" class="list-item__icon" :name="icon" size="md"></telia-icon>

    <div class="list-item__center">
      <p class="list-item__title" :title="title">
        {{ title }}
      </p>

      <telia-p v-if="hasBody" class="list-item__body" variant="additional-100">
        <slot />
      </telia-p>
    </div>

    <div class="list-item__right">
      <telia-p v-if="rightTitle" variant="additional-100">
        {{ rightTitle }}
      </telia-p>

      <telia-icon class="list-item__chevron" name="chevron-right" size="sm"></telia-icon>
    </div>
  </a>
</template>

<style lang="scss" scoped>
@import "../../styles/voca";

.list-item {
  $block: &;

  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: $telia-spacing-16;
  padding: $telia-spacing-12 0;
  text-align: left;
  text-decoration: none;
  @include telia-color("black");
  outline-color: $telia-purple-500;
  outline-offset: $telia-spacing-4;
  cursor: pointer;

  &:not(:last-child) {
    @include telia-border("gray-200", "xs", "bottom");
  }

  &:hover,
  &:focus {
    #{$block}__chevron,
    #{$block}__right,
    #{$block}__title {
      @include telia-color("purple-500");
    }
  }

  &--left-slot {
    grid-template-columns: $telia-spacing-48 1fr 8.8rem;
  }

  &--icon {
    grid-template-columns: $telia-spacing-24 1fr 8.8rem;
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include telia-paragraph-100;
    font-weight: $telia-typography-weight-bold;
    transition: all $telia-duration-150 $telia-ease-in-out;
  }

  &__body {
    hyphens: none;
  }

  &__chevron {
    transform: translateY($telia-spacing-4);
    transition: all $telia-duration-150 $telia-ease-in-out;
  }

  &__center {
    overflow: hidden;
  }

  &__right {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    text-align: right;
    transition: all $telia-duration-150 $telia-ease-in-out;
  }
}
</style>
