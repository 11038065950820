<template>
  <telia-p t-id="module-empty-state" class="module-empty-state" variant="paragraph-100">
    <slot />
  </telia-p>
</template>

<style lang="scss" scoped>
@import "../../../styles/voca";

.module-empty-state {
  padding: $telia-spacing-8;
  text-align: center;
  @include telia-color("gray-600");
}
</style>
