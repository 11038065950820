<template>
  <div class="skeleton-tabs__wrapper">
    <telia-skeleton class="skeleton-tabs__tab"></telia-skeleton>
    <telia-skeleton class="skeleton-tabs__tab"></telia-skeleton>
  </div>
</template>

<script>
export default {
  name: "SkeletonTabs",
};
</script>

<style lang="scss" scoped>
@import "../../../styles/voca";

.skeleton-tabs {
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @include telia-border("gray-200", "md", "bottom");
  }

  &__tab {
    height: $telia-spacing-24;
    width: 35%;
    margin-top: $telia-spacing-8;
    margin-bottom: $telia-spacing-12;
  }
}
</style>
