import { ref } from "vue";

export enum STATUS {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  WARNING = "WARNING",
}

const draggable = ref(true);
const status = ref<STATUS>(STATUS.LOADING);

export const useDashboard = () => {
  const setDraggable = (draggableState: boolean): void => {
    draggable.value = draggableState;
  };

  const setStatus = (newStatus: STATUS): void => {
    status.value = newStatus;
  };

  return { setDraggable, setStatus, draggable, status };
};
