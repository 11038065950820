<script setup lang="ts">
import { computed, watch, onBeforeMount } from "vue";
import { translateSetup, translateMixin } from "../locales";
import type { Module as ModuleType } from "../modules.index";

import ModuleItem from "./ModuleItem.vue";

interface ModulesList extends ModuleType {
  selected: boolean;
  displayAsNew: boolean;
}

const t = translateMixin.methods.t;

const props = defineProps<{
  modulesList: ModulesList[];
}>();

const emit = defineEmits<{
  (e: "module-toggled", id: string): void;
}>();

onBeforeMount(() => {
  getModulesListTranslate();
});

const sortedModules = computed(() => {
  return props.modulesList
    .map((module) => ({
      ...module,
      name: t(`MODULES.${module.name}`),
    }))
    .sort((moduleA, moduleB) => (moduleA.name > moduleB.name ? 1 : -1));
});

const getModulesListTranslate = () => {
  translateSetup(props.modulesList);
};

watch(() => props.modulesList, getModulesListTranslate);
</script>

<template>
  <div class="modules-panel">
    <telia-p>
      {{ t("DASHBOARD.ADD_MODULE_TEXT") }}
    </telia-p>
    <div class="modules-panel__modules">
      <ModuleItem
        v-for="sortedModule in sortedModules"
        :key="sortedModule.id"
        :name="sortedModule.name"
        :selected="sortedModule.selected"
        :icon="sortedModule.icon"
        :is-new="sortedModule.displayAsNew"
        @select="emit('module-toggled', sortedModule.id)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../../styles/voca";

.modules-panel {
  &__modules {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: $telia-spacing-32;
  }
}
</style>
