enum STATE {
  LOADING = "LOADING",
  SERVER_ERROR = "SERVER_ERROR",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  NO_AGREEMENT = "NO_AGREEMENT",
  NO_ITEMS = "NO_ITEMS",
  BAD_AGREEMENT = "BAD_AGREEMENT",
  ORGANISATION_SET = "ORGANISATION_SET",
  PRODUCTS_RETRIEVED = "PRODUCTS_RETRIEVED",
  NO_CUSTOMER = "NO_CUSTOMER",
  PRECONDITION_NOT_MET = "PRECONDITION_NOT_MET",
  RESET = "RESET",
  HIT_LIMIT_EXCEEDED = "HIT_LIMIT_EXCEEDED",
}

export default STATE;
