import { ref, computed, watch, onMounted } from "vue";
import { logInfo } from "@telia/b2x-logging";

import { useOrganisations } from "./organisations";
import STATE from "../components/Dashboard/state.enum";
import { wait } from "../helpers";

import Module from "../components/Dashboard/Module.vue";
import ModuleEmptyState from "../components/Dashboard/components/ModuleEmptyState.vue";

export type Props = {
  title: object;
  name: string;
  selectedOrganisation: string;
  scopeId: string;
  refreshLayout: () => void;
};

export type Emit = {
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
};

export const useModule = (props: Props, emit: Emit) => {
  const { organisations } = useOrganisations();

  onMounted(async () => {
    await refreshTheLayout();
  });

  const status = ref(STATE.LOADING);
  const moduleContainer = ref();
  const moduleLoader = ref();

  const hasMultipleOrganisations = computed(() => organisations.value.length > 1);
  const hasSelectedOrganisation = computed(
    () => props.selectedOrganisation && props.selectedOrganisation !== ""
  );
  const isLoading = computed(() => status.value === STATE.LOADING);

  const refreshTheLayout = async () => {
    await wait(10);
    await props.refreshLayout();
  };

  const setStatus = (state: STATE) => {
    status.value = state;
  };

  const logEmptyModule = () => {
    const moduleId = props.name;
    if (!moduleId) return;

    const logTscid = props.selectedOrganisation || "[EMPTY]";

    logInfo(
      "MyBusiness startpage",
      `The '${moduleId}' module is empty for the organisation with tscid: ${logTscid}`
    );
  };

  const onStatusChange = (status: STATE) => {
    emit("status", status);

    if (status === STATE.NO_ITEMS) {
      logEmptyModule();
    }

    const containerHeight = moduleContainer.value?.offsetHeight;

    if (moduleLoader.value && containerHeight) {
      moduleLoader.value.style.minHeight = containerHeight + "px";
    }

    refreshTheLayout();
  };

  watch(() => status.value, onStatusChange);

  return {
    setStatus,
    refreshTheLayout,
    props,
    hasMultipleOrganisations,
    hasSelectedOrganisation,
    isLoading,
    status,
    moduleContainer,
    moduleLoader,
    Module,
    ModuleEmptyState,
  };
};
