<script setup lang="ts">
import { computed, onBeforeMount, ref, watch, nextTick, onBeforeUnmount } from "vue";

import Muuri from "muuri";
import { useDebounceFn } from "@vueuse/core";
import { logError } from "@telia/b2x-logging";
import { currentLanguage } from "@telia/b2b-i18n";
import { addNewToast } from "@telia/b2b-message-service";
import type { DragSortPredicateResult, Item } from "muuri";
import { corpStartpageWidgets } from "@telia/b2b-rest-client";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { getTooltips, markTooltipAsSeen } from "@telia/b2b-user-settings";
import { isTeliaAdmin as getIsTeliaAdmin } from "@telia/b2b-logged-in-service";

import { usePage } from "../../composables/page";
import { useScope } from "../../composables/scope";
import { useDashboard, STATUS } from "../../composables/dashboard";
import { useOrganisations } from "../../composables/organisations";

import { numberToWord } from "../../helpers/numberToWord";

import Module from "./Module.vue";
import type { Module as ModuleType } from "./modules.index";
import { getAllModuleIds, getModuleById } from "./modules.index";
import { translateSetup, translateMixin, translateNewModules } from "./locales";

import { trackStartPageConfiguration } from "../../utils/trackGA4";

import ModulesPanel from "./components/ModulesPanel.vue";
import OrganisationSelector from "./components/OrganisationSelector.vue";

const state = {
  success: "SUCCESS",
  warning: "WARNING",
  error: "ERROR",
  loading: "LOADING",
};

type moduleRefsType = { [key: string]: InstanceType<typeof Module> };

interface UserModuleType extends ModuleType {
  selected: boolean;
  displayAsNew: boolean;
}

const newModulesHighlightId = "b2b-dashboard.new_modules";

const t = translateMixin.methods.t;
const { scopeId } = useScope();
const { onboardingModalOpen } = usePage();
const { setStatus, status, draggable, setDraggable } = useDashboard();
const { selectedOrganisation } = useOrganisations();

onBeforeMount(async () => {
  await translateSetup(null, ["mybusiness"]);
  setupEventListeners();

  try {
    isTeliaAdmin.value = await getIsTeliaAdmin();

    setStatus(STATUS.SUCCESS);
  } catch {
    logError("b2b-dashboard", "Failed to get scope details in the Dashboard component");

    setStatus(STATUS.ERROR);
  }

  if (!isTeliaAdmin && scopeId.value) {
    try {
      highlightsSeen.value = (await getTooltips(scopeId.value)) ?? [];
      highlightsError.value = false;
    } catch {
      logError("b2b-dashboard", "Failed to get tooltips in the Dashboard component");

      highlightsError.value = true;
    }
  }

  // TEMP: for first version of the endpoint does not support TSCID
  availableModulesStatus.value = state.loading;

  try {
    await fetchAvailableModules();

    availableModulesStatus.value = state.success;
  } catch {
    availableModulesStatus.value = state.error;
  }

  saveLayout();

  dashboardIsReady.value = true;
  modulesHighlightSeenCurrentVisit.value = showNewModulesHighlight.value;
});

onBeforeUnmount(() => {
  removeEventListeners();
});

const userModules = ref<UserModuleType[]>([]);
const availableModulesStatus = ref(state.loading);
const drawerOpen = ref(false);
const previousPos = ref(0);
const rotation = ref(0);
const grid = ref();
const dashboardIsReady = ref(false);
const userSavedLayoutLastSynced = ref<string[]>([]);
const enableSaveLayout = ref(false);
const moduleStatuses = ref({});
const dashboardLayoutReady = ref(false);
const highlightsSeen = ref<string[]>([]);
const highlightsError = ref(false);
const modulesHighlightSeenCurrentVisit = ref(false);
const isTeliaAdmin = ref(false);
const toastId = ref(0);

const modulesRefs = ref<moduleRefsType>({});

const shouldShowLoader = computed(() => {
  return (
    !dashboardLayoutReady.value ||
    status.value === STATUS.LOADING ||
    availableModulesStatus.value === STATUS.LOADING
  );
});

const shouldShowModules = computed(() => {
  return (
    dashboardIsReady.value ||
    ((status.value === state.success || status.value === state.warning) &&
      availableModulesStatus.value === state.success)
  );
});

const selectedModules = computed(() => {
  if (!dashboardIsReady.value) {
    return [];
  }
  return userModules.value.filter((item) => item.id && item.selected);
});

const newModules = computed(() => {
  return userModules.value.filter((module) => module.displayAsNew);
});

const newModulesHighlightSeen = computed(() => {
  return highlightsSeen.value.includes(newModulesHighlightId);
});

const showNewModulesHighlight = computed(() => {
  return (
    !isTeliaAdmin &&
    newModules.value.length > 0 &&
    !onboardingModalOpen.value &&
    !newModulesHighlightSeen.value &&
    !highlightsError.value
  );
});

const fetchAvailableModules = async () => {
  if (!scopeId.value) return;

  await corpStartpageWidgets.WidgetControllerService.getModules(scopeId.value)
    .then((response) => {
      const allModuleIds = getAllModuleIds();

      const filteredUserModules =
        response.modules?.filter((module: corpStartpageWidgets.ModuleUI) => {
          return allModuleIds.includes(module.id ?? "");
        }) ?? [];

      userModules.value = addModulesDetails(filteredUserModules);

      response.modules?.forEach((module) => {
        if (module.selected && module.id) {
          userSavedLayoutLastSynced.value.push(module.id);
        }
      });

      enableSaveLayout.value = true;
    })
    .catch(() => {
      logError("b2b-dashboard", "Failed to get available modules in the Dashboard component");

      addNewToast(
        `b2b-dashboard-${toastId.value++}`,
        "error",
        t("DASHBOARD.ERROR.FETCH_MODULES"),
        ""
      );
    });
};

const markNewModulesHighlightAsSeen = async () => {
  highlightsSeen.value.push(newModulesHighlightId);

  try {
    await markTooltipAsSeen(scopeId.value, newModulesHighlightId);
  } catch {
    logError(
      "b2b-dashboard",
      "Failed to mark new modules highlight as seen in the Dashboard component"
    );
  }
};

const markNewModulesAsSeen = async () => {
  if (isTeliaAdmin.value) return;

  const modules = newModules.value.map((module) => ({ id: module.id, displayAsNew: false }));
  try {
    await corpStartpageWidgets.WidgetControllerService.patchModulesSelected(scopeId.value, modules);
  } catch {
    logError("b2b-dashboard", "Failed to mark new modules as seen in the Dashboard component");
  }
};

const gridInit = async () => {
  let phPool: HTMLElement[] = [];
  let phElem = document.createElement("div");

  if (!document.querySelector(".dashboard-modules .modules-container")) return;

  grid.value = new Muuri(".dashboard-modules .modules-container", {
    dragEnabled: true,
    dragHandle: ".module__header",
    itemPlaceholderClass: "item-placeholder",
    layoutOnResize: false,
    layout: {
      rounding: false,
    },
    dragPlaceholder: {
      enabled: true,
      createElement(): HTMLElement {
        return phPool.pop() || (phElem.cloneNode() as HTMLElement);
      },
      onRemove(item, element) {
        phPool.push(element);
      },
    },
    dragSortPredicate: function (item): DragSortPredicateResult {
      let result = Muuri.ItemDrag.defaultSortPredicate(item, {
        action: "swap",
        threshold: 50,
      });
      let lastItem = result && result.index === result.grid.getItems().length - 1;

      return lastItem ? null : result;
    },
    dragStartPredicate: (item, event) => {
      if (isMobileView()) {
        return false;
      }

      const moduleId = item.getElement()?.getAttribute("data-id");

      if (!moduleId || moduleStatuses.value[moduleId] === "LOADING") {
        return false;
      }

      return Muuri.ItemDrag.defaultStartPredicate(item, event);
    },
  });

  grid.value.on("dragStart", function (item: Item) {
    previousPos.value = item.getPosition().left;
    rotation.value = 0;
  });

  grid.value.on("dragMove", (item: Item) => onDragMove(item));
  grid.value.on("dragReleaseEnd", (module) => {
    let moduleIndex = grid.value.getItems().indexOf(module) + 1;
    let moduleName = module.getElement().getAttribute("data-id");

    if (layoutIsChanged()) {
      analytics.trackEvent(
        analytics.category.START_PAGE_MODULE,
        analytics.action.MOVE,
        `${moduleName} modul flyttad till plats ${moduleIndex}`
      );
    }

    saveLayout();

    // By moving a module the dashboard grid will refresh in case that it is broken
    refreshLayout();
  });

  grid.value.on("layoutEnd", () => {
    setTimeout(() => {
      dashboardLayoutReady.value = true;
    }, 400);
  });
};

const addModulesDetails = (userModules: corpStartpageWidgets.ModuleUI[]): UserModuleType[] => {
  return userModules
    .map((userModule) => {
      const moduleDetails = getModuleById(userModule.id ?? "");
      return {
        ...moduleDetails,
        selected: userModule.selected,
        displayAsNew: userModule.displayAsNew,
      };
    })
    .filter((userModule) => !!userModule) as UserModuleType[];
};

const moduleToggled = async (id: string) => {
  let toggledModule = userModules.value.find((item) => item.id === id);

  let gridItems = grid.value.getItems();
  let toggledModuleEl = gridItems.find(
    (item: Item) => item.getElement()?.getAttribute("data-id") === id
  );

  if (!toggledModule) return;

  // Track hidden module to GA
  if (toggledModule.selected) {
    analytics.trackEvent(analytics.category.START_PAGE, analytics.action.HIDE, `Dölj ${id} modul`);
  } else {
    analytics.trackEvent(
      analytics.category.START_PAGE,
      analytics.action.ADD,
      `Lägg till ${id} modul`,
      null,
      [
        {
          type: analytics.customDimensions.FEATURE_HIGHLIGHT,
          value: `${modulesHighlightSeenCurrentVisit && toggledModule.displayAsNew}`,
        },
      ]
    );
  }

  // Toggle selected i userModules
  userModules.value.map((module) => {
    if (module.id === id) {
      module.selected = !module.selected;
    }
  });

  // Toggle Modules on Dashboard
  if (toggledModuleEl) {
    grid.value.remove(grid.value.getItems(toggledModuleEl), { removeElements: true });
  } else {
    await nextTick();

    const moduleElement = modulesRefs.value[`module-${id}`].$el;

    if (moduleElement) {
      grid.value.add(moduleElement, {
        index: Math.max(0, gridItems.length - 1),
      });
    }
  }

  refreshLayout();
  await saveLayout();
};

const getSortedLayout = () => {
  let layout: Record<string, string | boolean>[] = [];
  let modulesOrderInGrid = getItemIdsFromGrid();
  modulesOrderInGrid.forEach((module) => {
    let matchingModule = userModules.value.filter((userModule) => userModule.id === module)[0];
    if (matchingModule) {
      layout.push({ id: matchingModule.id, selected: matchingModule.selected });
    }
  });

  let notSelectedModules = userModules.value.filter((userModule) => userModule.selected === false);
  notSelectedModules.forEach((module) => {
    layout.push({ id: module.id, selected: module.selected });
  });

  return layout;
};

let saveLayout = useDebounceFn(async (force = false): Promise<void> => {
  if (isTeliaAdmin.value) return;

  const itemIds = getItemIdsFromGrid();
  if ((enableSaveLayout.value && layoutIsChanged()) || force) {
    const layout = getSortedLayout();

    try {
      await corpStartpageWidgets.WidgetControllerService.putModules(scopeId.value, layout);

      userSavedLayoutLastSynced.value = [...itemIds];
    } catch {
      logError("b2b-dashboard", "Failed to save changed layout in the Dashboard component");

      addNewToast(
        `b2b-dashboard-${toastId.value++}`,
        "warning",
        t("DASHBOARD.ERROR.SAVE_LAYOUT"),
        ""
      );
    }
  }
}, 1000);

const layoutIsChanged = () => {
  return JSON.stringify(getItemIdsFromGrid()) !== JSON.stringify(userSavedLayoutLastSynced.value);
};

const refreshLayout = () => {
  grid.value.refreshItems().layout();
};

const onDragMove = (item: Item) => {
  let velocity = item.getPosition().left - previousPos.value;
  let newRotation = rotation.value || 0;
  let rotationMin = 0;
  let rotationMax = 2;
  let rotationOffset = (rotationMax - rotationMin) * 0.8;
  let rotationMitigation = 0.2;

  newRotation =
    newRotation * (1 - rotationMitigation) +
    (velocity / (1 + Math.abs(velocity))) * (rotationMin + rotationOffset);

  if (Math.abs(newRotation) < 0.01) newRotation = 0;

  const element = item.getElement();

  if (element && element.style.transform.includes("rotate")) {
    element.style.transform = element.style.transform + `rotate(${newRotation}deg)`;
  }

  rotation.value = newRotation;
  previousPos.value = item.getPosition().left;
};

const hideModulesDrawer = () => {
  drawerOpen.value = false;
  trackStartPageConfiguration("Modules drawer", "close");

  if (newModules.value.length) markNewModulesAsSeen();
};

const showModulesDrawer = () => {
  analytics.trackEvent(analytics.category.START_PAGE, analytics.action.OPEN, "Visa moduler", null, [
    {
      type: analytics.customDimensions.FEATURE_HIGHLIGHT,
      value: `${modulesHighlightSeenCurrentVisit.value}`,
    },
  ]);
  trackStartPageConfiguration("Modules drawer", "open");

  drawerOpen.value = true;
};

const getItemIdsFromGrid = (): string[] => {
  return grid.value.getItems().map((item: Item) => item.getElement()?.getAttribute("data-id"));
};

const isMobileView = () => {
  return window.innerWidth < 768;
};

const onStatusChange = (status: STATUS) => {
  if (status === state.warning) {
    addNewToast(
      `b2b-dashboard-${toastId.value++}`,
      "warning",
      t("DASHBOARD.ERROR.ORGANISATION"),
      ""
    );
  }
};

const onShouldShowModules = async () => {
  await nextTick();
  gridInit();

  if (newModules.value.length) {
    await saveLayout(true);
  }
};

const onModuleStatusChange = (moduleId: string, status: STATUS) => {
  moduleStatuses.value[moduleId] = status;
};

const getNewModulesHeading = computed(() => {
  return newModules.value.length > 1 ? t("mybusiness.NEW_MODULES") : t("mybusiness.NEW_MODULE");
});

const getNewModulesBody = computed(() => {
  translateNewModules(newModules.value);

  let newModulesList = newModules.value.map((module) => {
    return t("NEW_MODULES." + module.name);
  });

  if (newModules.value.length > 3) {
    newModulesList = [
      ...newModulesList.slice(0, 2),
      t("{DASHBOARD__NEW_MODULES_MORE}", {
        countAsText: numberToWord(newModules.value.length - 2, currentLanguage()),
      }),
    ];
  }

  const formatter = new Intl.ListFormat(currentLanguage(), {
    style: "long",
    type: "conjunction",
  });

  return t("{DASHBOARD__NEW_MODULES_BODY}", {
    countAsInt: newModules.value.length,
    countAsText: numberToWord(newModules.value.length, currentLanguage()),
    moduleNames: formatter.format(newModulesList),
  });
});

const setupEventListeners = () => {
  document.addEventListener("visibilitychange", onVisibilityChange);
  window.addEventListener("resize", onWindowResize);
};

const removeEventListeners = () => {
  document.removeEventListener("visibilitychange", onVisibilityChange);
  window.removeEventListener("resize", onWindowResize);
};

const onVisibilityChange = useDebounceFn(async () => {
  if (document.visibilityState === "visible") {
    refreshLayout();
  }
}, 100);

const onWindowResize = useDebounceFn(() => {
  if ((isMobileView() && draggable.value) || (!isMobileView() && !draggable.value)) {
    setDraggable(!draggable.value);
  }

  refreshLayout();
}, 100);

watch(() => shouldShowModules.value, onShouldShowModules);
watch(() => status.value, onStatusChange);
</script>

<template>
  <div id="t-dashboard" class="dashboard-modules">
    <div class="dashboard__notification">
      <telia-notification
        v-if="status === 'ERROR'"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("DASHBOARD.ERROR.DEFAULT.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("DASHBOARD.ERROR.DEFAULT.TITLE") }}
        </span>
      </telia-notification>
    </div>

    <div class="dashboard-head">
      <OrganisationSelector @error="setStatus(STATUS.WARNING)" />

      <telia-skeleton
        v-if="shouldShowLoader"
        class="dashboard__add-module-skeleton"
      ></telia-skeleton>

      <b2x-feature-highlight
        v-if="!shouldShowLoader"
        :show-popover="showNewModulesHighlight"
        :heading="getNewModulesHeading"
        :body="getNewModulesBody"
        placement="top-end"
        @afterClosePopover="markNewModulesHighlightAsSeen"
      >
        <telia-button
          t-id="addModuleButton"
          class="add-module-button"
          variant="text"
          @click="showModulesDrawer"
        >
          {{ t("DASHBOARD.ADD_MODULE") }}&nbsp;
          <span v-if="newModules.length">
            <strong> ({{ t("{DASHBOARD__NEW}", { count: newModules.length }) }}) </strong>
          </span>
          <telia-icon name="add" size="md" slot="right"></telia-icon>
        </telia-button>
      </b2x-feature-highlight>
    </div>

    <div v-if="shouldShowLoader" class="dashboard__module-skeletons">
      <telia-skeleton class="dashboard__module-skeleton"></telia-skeleton>
      <telia-skeleton class="dashboard__module-skeleton"></telia-skeleton>
      <telia-skeleton class="dashboard__module-skeleton"></telia-skeleton>
      <telia-skeleton class="dashboard__module-skeleton"></telia-skeleton>
      <telia-skeleton class="dashboard__module-skeleton"></telia-skeleton>
    </div>

    <transition-group
      name="fade"
      tag="div"
      class="modules-container"
      :class="{
        'dashboard__modules--ready': dashboardLayoutReady,
        'dashboard__modules--loading': !dashboardLayoutReady,
      }"
      v-if="shouldShowModules"
    >
      <!-- Modules -->
      <component
        v-for="selectedModule in selectedModules"
        :key="selectedModule.id"
        :is="selectedModule.component"
        :title="selectedModule.title"
        :name="selectedModule.name"
        :ref="
          (el: InstanceType<typeof Module>) => {
            modulesRefs[`module-${selectedModule.id}`] = el;
          }
        "
        :selectedOrganisation="selectedOrganisation"
        :refreshLayout="refreshLayout"
        :scope-id="scopeId"
        @module-removed="moduleToggled"
        @status="onModuleStatusChange(selectedModule.id, $event)"
      ></component>

      <!-- Add/Remove Modules -->
      <Module
        :title="t('DASHBOARD.ADD_MODULE')"
        moduleStyle="addModule"
        id="AddModules"
        key="addModules"
        ref="addModules"
        :paddedContent="false"
      >
        <button @click="showModulesDrawer" v-html="t('DASHBOARD.ADD_MODULE')"></button>
      </Module>
    </transition-group>

    <!-- Modules Panel -->
    <b2x-drawer
      :heading="t('DASHBOARD.ADD_MODULE')"
      drawer-id="dashboard-drawer"
      position="right"
      :is-open="drawerOpen"
      @drawerClose="hideModulesDrawer"
      v-if="shouldShowModules"
    >
      <ModulesPanel @module-toggled="moduleToggled" :modulesList="userModules" />
    </b2x-drawer>
  </div>
</template>

<style lang="scss" src="./styles/dashboard.scss"></style>
