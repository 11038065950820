import { trackGa4, getGa4DefaultParams } from "@telia/b2b-analytics";

const sharedValues = {
  ...getGa4DefaultParams(),
  logged_in: true,
  page_type: "start",
};

export const trackStartPageConfiguration = (module_text: string, module_action: string) => {
  trackGa4("startpage_configuration", {
    ...sharedValues,
    module_text,
    module_action,
  });
};

export const trackNavigationInteraction = (click_text: string, click_action: string) => {
  trackGa4("navigation_interaction", {
    ...sharedValues,
    click_type: "module_navigation",
    click_text,
    click_action,
  });
};

export const trackPukCodeInteraction = (action: string) => {
  trackGa4("mobile_management", {
    ...sharedValues,
    site_version: "mc_online",
    mobile_management_action: action,
  });
};
