<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { fetchDashboardRecommendations } from "@telia/b2b-recommendations";

import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import { translateSetup, translateMixin } from "./locales";

import STATE from "../../state.enum";

import Recommendation from "./components/Recommendation.vue";

type ReturnRecommendation = {
  id: string;
  title: string;
  richPreamble: string;
  image: {
    url: string;
    altText: string;
  };
};

onBeforeMount(async () => {
  isRecommendationsLoading.value = true;
  translateSetup(props.title, props.name);
  await setupRecommendations();
  isRecommendationsLoading.value = false;
  await refreshTheLayout();
});

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const isRecommendationsLoading = ref(false);
const teasers = ref<ReturnRecommendation[] | null>(null);

const t = translateMixin.methods.t;

const { refreshTheLayout, moduleContainer, Module } = useModule(props, emit);

const { selectedOrganisation } = useOrganisations();

const setupRecommendations = async () => {
  try {
    teasers.value = await fetchDashboardRecommendations(selectedOrganisation.value);
  } catch {
    logError(
      "b2b-dashboard",
      "Failed to fetch customer recommendations in the Recommendations module"
    );

    teasers.value = null;
  }
};
</script>

<template>
  <Module
    id="Recommendations"
    :title="t('RECOMMENDATIONS.MODULE_TITLE')"
    :paddedContent="false"
    @module-removed="emit('module-removed', $event)"
  >
    <div ref="moduleContainer">
      <Recommendation
        t-id="recommendation"
        v-if="!isRecommendationsLoading && teasers"
        :teaser="teasers[0]"
      />
      <div v-else-if="!isRecommendationsLoading && !teasers" class="notification-wrapper">
        <telia-notification
          t-id="recommendation-notification"
          status="warning"
          variant="inline"
          heading-tag="h3"
          html-aria-live="polite"
          html-role="alert"
        >
          <span slot="heading">
            <telia-visually-hidden>
              {{ t("RECOMMENDATIONS.ERROR.A11Y_STATUS") }}
            </telia-visually-hidden>
            {{ t("RECOMMENDATIONS.ERROR") }}
          </span>
        </telia-notification>
      </div>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.notification-wrapper {
  padding: $telia-spacing-12;
}
</style>
