import { supportCorpCsmDisturbance } from "@telia/b2x-support-rest-client";

export const getSystemDisturbances = (
  xAuthScopeId: string,
  organizationNumber: string,
  acceptLanguage?: string
): ReturnType<
  typeof supportCorpCsmDisturbance.DisturbanceControllerV2Service.getDisturbanceServices1
> => {
  return supportCorpCsmDisturbance.DisturbanceControllerV2Service.getDisturbanceServices1(
    xAuthScopeId,
    organizationNumber,
    acceptLanguage
  );
};
