import { ref } from "vue";

const onboardingModalOpen = ref(false);

export const usePage = () => {
  const openOnboardingModal = () => {
    onboardingModalOpen.value = true;
  };

  const closeOnboardingModal = () => {
    onboardingModalOpen.value = false;
  };

  return { openOnboardingModal, closeOnboardingModal, onboardingModalOpen };
};
