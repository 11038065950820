<template>
  <div class="local-error">
    <telia-icon class="local-error__icon" size="sm" name="error-filled" />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/voca";

.local-error {
  @include telia-additional-100;
  @include telia-color("red-600");
  display: flex;
  flex-direction: row;

  &__icon {
    flex: none;
    line-height: 1.5;
    margin: 0.3rem $telia-spacing-4 0 0;
    vertical-align: middle;
  }
}
</style>
