<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { translateSetup, translateMixin } from "./locales";

const props = defineProps<{
  title: object;
  name: string;
}>();

const emit = defineEmits<{
  (e: "module-removed", event: Event): void;
}>();

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
});

const t = translateMixin.methods.t;

const news = ref({
  title: "Telias växellösningar",
  content:
    "<p>Vi arbetar hela tiden med att göra företagssidorna ännu bättre. Detta är våra senaste funktioner och förbättringar.</p><p>För kunder med Telias växellösningar, har vi uppdaterat detaljsidan till ny design under Hantera Produkter och Tjänster</p>",
});
</script>

<template>
  <Module
    id="News"
    :title="t('NEWS.MODULE_TITLE')"
    :link-title="t('NEWS.LINK_MORE_NEWS')"
    link-href="/foretag/mybusiness/fakturor"
    @module-removed="emit('module-removed', $event)"
  >
    <strong>{{ news.title }}</strong>
    <div v-html="news.content" />
  </Module>
</template>
