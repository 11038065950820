<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { getServiceWebs as fetchServiceWebs } from "@telia/b2b-logged-in-service";

import { useModule } from "../../../../composables/module";

import { translateSetup, translateMixin } from "./locales";

import LinkCard from "../../../shared/LinkCard.vue";
import LocalError from "../../../shared/LocalError.vue";

import STATE from "../../state.enum";

import type { Report, ReportCategory } from "./utils/statistics";
import { getFilteredReportCategories, openQlik } from "./utils/statistics";

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
  await getAvailableStatistics();
});

const t = translateMixin.methods.t;

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const { isLoading, status, moduleContainer, Module } = useModule(props, emit);

const availableStatistics = ref<ReportCategory[]>([]);
const errorText = ref({});
const selectedReport = ref<string | null>(null);

const getAvailableStatistics = async () => {
  try {
    const serviceWebs = (await fetchServiceWebs()) ?? [];

    availableStatistics.value = await getFilteredReportCategories(props.scopeId, serviceWebs);

    status.value = STATE.SUCCESS;
  } catch {
    logError(
      "b2b-dashboard",
      "Failed to fetch available statistics in the Statistics and reports module"
    );

    status.value = STATE.ERROR;
  }
};

const getReportTarget = (report: Report) => {
  return report.link.startsWith("/foretag/mybusiness") || report.sessionType ? "_self" : "_blank";
};

const isReportDisabled = (report: Report) => {
  return selectedReport.value !== report.id && status.value === STATE.LOADING;
};

const isReportLoading = (report: Report) => {
  return selectedReport.value === report.id && status.value === STATE.LOADING;
};

const onClickReport = async (event: Event, report: Report) => {
  if (report.sessionType) {
    event.preventDefault();

    status.value = STATE.LOADING;
    selectedReport.value = report.id;

    try {
      const qlikResponse = await openQlik(props.scopeId, report.sessionType);
      window.open(qlikResponse.url);

      status.value = STATE.SUCCESS;
      errorText.value = {};
    } catch (error) {
      logError("b2b-dashboard", "Failed to open Qlik report in the Statistics and reports module");

      status.value = STATE.ERROR;
      errorText.value = {
        [report.id]: error,
      };
    }
  } else {
    status.value = STATE.SUCCESS;
    selectedReport.value = null;
  }
};
</script>

<template>
  <Module
    id="StatisticsAndReports"
    :title="t('STATISTICS_AND_REPORTS.MODULE_TITLE')"
    :loading="isLoading"
    @module-removed="emit('module-removed', $event)"
  >
    <div class="reports-container" ref="moduleContainer">
      <div class="statistics-list">
        <section
          class="statistics-section"
          v-for="category in availableStatistics"
          :key="category.name"
        >
          <div class="statistics-section__title">
            <telia-icon v-if="category.icon" :name="category.icon" size="md"></telia-icon>
            <telia-p>
              {{ t(category.name) }}
            </telia-p>
          </div>

          <LinkCard
            :t-id="`report-link-${report.id}`"
            :key="report.id"
            v-for="report in category.reports"
            :href="report.sessionType ? '' : report.link"
            :title="t(report.title)"
            :loading="isReportLoading(report)"
            :disabled="isReportDisabled(report)"
            :target="getReportTarget(report)"
            @click="onClickReport($event, report)"
          >
            {{ t(report.description) }}

            <LocalError v-if="errorText[report.id] && selectedReport === report.id">
              {{ t(errorText[report.id]) }}
            </LocalError>
          </LinkCard>
        </section>
      </div>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.statistics-section {
  &:not(:last-child) {
    margin-bottom: $telia-spacing-24;
  }

  &__title {
    margin-bottom: $telia-spacing-16;
    line-height: $telia-spacing-24;
    vertical-align: middle;
    display: flex;

    telia-icon {
      margin-right: $telia-spacing-4;
    }
  }
}
</style>
