<script setup lang="ts">
import { computed, nextTick } from "vue";
import { trackNavigationInteraction } from "../../utils/trackGA4";

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    href: string;
    icon?: string;
    loading?: boolean;
    target?: string;
    title: string;
    external?: boolean;
    gaText?: string;
  }>(),
  {
    disabled: false,
    loading: false,
    external: false,
  }
);

const emit = defineEmits(["click"]);

const linkRel = computed(() => {
  return props.target === "_blank" ? "noopener noreferrer" : undefined;
});

const onClick = async (event: Event) => {
  if (props.loading || props.disabled) {
    event.preventDefault();
  } else {
    trackNavigationInteraction(props.gaText ? props.gaText : props.title, "click");
    await nextTick();
    emit("click", event);
  }
};
</script>

<template>
  <a
    class="link-card"
    :class="{
      'link-card--disabled': disabled,
      'link-card--icon': icon,
      'link-card--left-slot': $slots.left,
      'link-card--loading': loading,
      'link-card--no-body': !$slots.default && !icon,
    }"
    :href="disabled || loading ? undefined : href"
    :target="target"
    :rel="linkRel"
    :aria-disabled="disabled"
    @click="onClick"
  >
    <transition name="fade">
      <div v-if="loading" class="link-card__loader">
        <b2x-spinner size="small" color="core-purple-light" t-id="b2x-spinner"></b2x-spinner>
      </div>
    </transition>

    <div v-if="$slots.left" class="link-card__left">
      <slot name="left" />
    </div>

    <telia-icon v-if="icon" class="link-card__icon" :name="icon" size="md"></telia-icon>

    <telia-text-spacing>
      <telia-heading tag="h6" class="link-card__title" variant="subsection-100">
        <strong>{{ title }}</strong>
      </telia-heading>

      <telia-p v-if="$slots.default" class="link-card__body" variant="additional-100">
        <slot />
      </telia-p>
    </telia-text-spacing>

    <telia-icon
      class="link-card__chevron"
      :name="external ? 'external' : 'chevron-right'"
      size="sm"
    ></telia-icon>
  </a>
</template>

<style lang="scss" scoped>
@import "../../styles/voca";

.link-card {
  $block: &;

  position: relative;
  display: grid;
  grid-template-columns: 1fr $telia-spacing-16;
  column-gap: $telia-spacing-16;
  padding: $telia-spacing-12 $telia-spacing-16;
  text-align: left;
  text-decoration: none;
  @include telia-color("black");
  @include telia-bg-color("gray-50");
  @include telia-border("transparent", "none");
  @include telia-border-radius("default");
  outline-color: $telia-purple-500;
  box-shadow: 0 0 0 $telia-border-width-1 $telia-gray-200;
  transition: all $telia-duration-150 $telia-ease-in-out;

  &:not(:last-child) {
    margin-bottom: $telia-spacing-16;
  }

  &:not(&--disabled):not(&--loading) {
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 $telia-border-width-2 $telia-purple-500;

      #{$block}__chevron,
      #{$block}__title {
        @include telia-color("purple-500");
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    @include telia-color("gray-600");
    @include telia-bg-color("gray-100");
  }

  &--left-slot {
    grid-template-columns: $telia-spacing-48 1fr $telia-spacing-16;
  }

  &--icon {
    grid-template-columns: $telia-spacing-24 1fr $telia-spacing-16;
  }

  &--loading {
    cursor: progress;
    @include telia-color("gray-500");
  }

  &__title {
    transition: all $telia-duration-150 $telia-ease-in-out;
  }

  &__body {
    hyphens: none;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($telia-white, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__chevron {
    transform: translateY($telia-spacing-4);
    transition: all $telia-duration-150 $telia-ease-in-out;
  }
}

.link-card--no-body .link-card__title > strong {
  line-height: $telia-spacing-48;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 20rem;
}

.link-card--no-body .link-card__chevron {
  transform: translateY($telia-spacing-16);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $telia-duration-200 $telia-ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
