<script setup lang="ts">
import { ref, onBeforeMount, computed, watch } from "vue";

import { logError } from "@telia/b2x-logging";
import { currentLanguage } from "@telia/b2b-i18n";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { corpCustomerInvoices } from "@telia/b2b-rest-client";

import { useScope } from "../../../../composables/scope";
import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import formatCurrencyHelper from "../../../../helpers/currency.js";

import { translateSetup, translateMixin } from "./locales";

import STATE from "../../state.enum";

type Invoice = {
  invoiceNumber: string;
  invoiceDate: string;
  invoiceDueDate: string;
  totalAmount: number | string;
  paymentStatus: string;
  billingSystemID: string;
  pdfHref: string;
};

const PAYMENT_STATUS = {
  PARTLY_PAID_OR_CREDITED: "PARTLY_PAID_OR_CREDITED",
  FULLY_PAID: "FULLY_PAID",
  NOT_PAID: "NOT_PAID",
  OVERDUE: "OVERDUE",
} as const;

const PAST_INVOICES_MONTHS = 3;

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const invoices = ref<Invoice[]>([]);
const skeletonInvoiceCount = ref(3);
const activeRow = ref<number | null>(null);
const t = translateMixin.methods.t;

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
  await getInvoices();
});

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  moduleLoader,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

const { selectedOrganisation } = useOrganisations();

const { scopeId } = useScope();

const shouldShowSubtitle = computed(() => {
  return status.value === STATE.SUCCESS || status.value === STATE.NO_ITEMS;
});

const shouldShowFooter = computed(() => {
  return (
    status.value === STATE.SUCCESS ||
    status.value === STATE.NO_ITEMS ||
    status.value === STATE.PRECONDITION_NOT_MET
  );
});

const containerHeight = computed(() => {
  const tableRowHeight = 53;
  const tableHeadingHeight = 56;
  const spacer = 61;

  return invoices.value.length * tableRowHeight + tableHeadingHeight + spacer;
});

const getStatusIcon = (paymentStatus: string) => {
  return paymentStatus === PAYMENT_STATUS.NOT_PAID ? "invoice" : null;
};

const getStatusVariant = (paymentStatus: string) => {
  switch (paymentStatus) {
    case PAYMENT_STATUS.FULLY_PAID:
      return "positive";
    case PAYMENT_STATUS.PARTLY_PAID_OR_CREDITED:
      return "caution";
    case PAYMENT_STATUS.NOT_PAID:
      return "neutral";
    case PAYMENT_STATUS.OVERDUE:
      return "warning";
    default:
      return "neutral";
  }
};

const formatCurrency = (amount: number | string) => {
  if (typeof amount === "string") return amount;
  return formatCurrencyHelper(currentLanguage(), Math.round(amount));
};

const getStatusText = (status: string) => {
  switch (status) {
    case PAYMENT_STATUS.FULLY_PAID:
    case PAYMENT_STATUS.PARTLY_PAID_OR_CREDITED:
    case PAYMENT_STATUS.NOT_PAID:
    case PAYMENT_STATUS.OVERDUE:
      return t(`INVOICES.PAYMENT_STATUSES.${status}`);
    default:
      return t("INVOICES.PAYMENT_STATUSES.UNKNOWN_STATUS");
  }
};

const getInvoices = async () => {
  try {
    if (!hasSelectedOrganisation.value) {
      status.value = STATE.PRECONDITION_NOT_MET;
      return;
    }

    invoices.value = [];
    status.value = STATE.LOADING;

    const fromInvoiceDate = (() => {
      const today = new Date();
      today.setMonth(today.getMonth() - PAST_INVOICES_MONTHS);
      return today.toLocaleDateString("sv");
    })();

    corpCustomerInvoices.CorpCustomerInvoicesService;
    const invoiceList =
      await corpCustomerInvoices.CorpCustomerInvoicesService.getInvoiceInformation(
        scopeId.value,
        selectedOrganisation.value,
        fromInvoiceDate,
        undefined,
        1,
        5
      );

    invoiceList.invoiceMetaList?.forEach((invoiceItem) => {
      invoiceItem.invoiceList?.forEach((invoice) => {
        let pdfHref = getInvoiceHref(invoice.invoiceNumber ?? "", invoice.billingSystemID ?? "");

        invoices.value.push({
          invoiceNumber: invoice.invoiceNumber ?? "",
          invoiceDate: invoice.invoiceDate ?? "",
          invoiceDueDate: invoice.invoiceDueDate ?? "",
          totalAmount: invoice.totalAmount ?? "",
          paymentStatus: invoice.paymentStatus ?? "",
          billingSystemID: invoice.billingSystemID ?? "",
          pdfHref,
        });
      });
    });

    status.value = invoices.value.length ? STATE.SUCCESS : STATE.NO_ITEMS;
    skeletonInvoiceCount.value = Math.max(invoices.value.length, 1);
  } catch {
    logError("b2b-dashboard", "Failed to fetch customer invoices in the invoices module");

    status.value = STATE.ERROR;
  } finally {
    await refreshTheLayout();
  }
};

const getInvoiceHref = (invoiceId: string, billingSystemID: string) => {
  return `/.api/corp-customer-invoices/v1/${scopeId.value}/pdf?tscid=${selectedOrganisation.value}&invoiceId=${invoiceId}&billingSystemID=${billingSystemID}`;
};

//TODO: event is coming from a click listener on a DIV, needs to be fixed for A11y.
const showInvoiceTools = (event, index: number) => {
  const clickedElement = event.target;

  if (
    clickedElement.classList.contains("invoice__table-row-tools") ||
    clickedElement.closest(".invoice__table-row-tools")
  ) {
    return;
  }

  activeRow.value = activeRow.value !== index ? index : null;
};

const previewInvoice = (invoice: Invoice) => {
  const openPreviewevent = new CustomEvent("openPreviewInvoiceDrawer", {
    detail: {
      invoice: {
        invoiceNumber: invoice.invoiceNumber,
        scopeId: scopeId.value,
        tscid: selectedOrganisation.value,
        paymentStatus: invoice.paymentStatus,
        billingSystemID: invoice.billingSystemID,
      },
    },
  });

  document.querySelector("body")?.dispatchEvent(openPreviewevent);

  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Invoices_preview"
  );
};

const trackEventInvoiceClicked = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Faktura klickad"
  );
};

watch(() => selectedOrganisation.value, getInvoices);
</script>

<template>
  <Module
    id="Invoices"
    :title="t('INVOICES.MODULE_TITLE')"
    :link-title="t('INVOICES.LINK_ALL_INVOICES')"
    :link-href="`/foretag/mybusiness/${scopeId}/fakturor/fakturor`"
    :paddedContent="status === STATE.ERROR"
    @module-removed="emit('module-removed', $event)"
    :footerDisabled="!shouldShowFooter"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :loading="isLoading"
    :subtitle="shouldShowSubtitle ? t('INVOICES.MODULE_SUBTITLE') : undefined"
  >
    <div
      class="invoice__container"
      ref="moduleContainer"
      :style="{ minHeight: containerHeight + 'px' }"
    >
      <div v-if="isLoading" class="invoice__skeleton-wrapper" ref="moduleLoader" key="moduleLoader">
        <div class="invoice__skeleton--row invoice__skeleton--heading">
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
        </div>
        <div class="invoice__skeleton--row" v-for="index in skeletonInvoiceCount" :key="index">
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
          <telia-skeleton class="invoice__skeleton invoice__skeleton--cell"></telia-skeleton>
        </div>
      </div>

      <div v-if="status === STATE.SUCCESS" class="invoice__table">
        <div class="invoice__table-row invoice__table-row--header">
          <div class="invoice__table-cell invoice__table-cell--status invoice__table-cell--heading">
            {{ t("INVOICES.PAYMENT_STATUS") }}
          </div>
          <div class="invoice__table-cell invoice__table-cell--heading">
            {{ t("INVOICES.DATE") }}
          </div>
          <div class="invoice__table-cell invoice__table-cell--amount invoice__table-cell--heading">
            {{ t("INVOICES.AMOUNT") }}
          </div>
        </div>

        <div
          v-for="(invoice, index) in invoices"
          class="invoice__table-row"
          :class="activeRow === index ? 'invoice__table-row--active' : ''"
          :key="invoice.invoiceNumber"
          @click="showInvoiceTools($event, index)"
        >
          <div class="invoice__table-cell invoice__table-cell--status">
            <telia-status-badge
              :variant="getStatusVariant(invoice.paymentStatus)"
              :icon="getStatusIcon(invoice.paymentStatus)"
            >
              {{ getStatusText(invoice.paymentStatus) }}
            </telia-status-badge>
          </div>
          <div class="invoice__table-cell invoice__table-cell--date">
            {{ invoice.invoiceDueDate }}
          </div>
          <div class="invoice__table-cell invoice__table-cell--amount">
            {{ formatCurrency(invoice.totalAmount) }}
          </div>

          <div class="invoice__table-row-tools">
            <telia-link
              class="invoice__table-download"
              :href="invoice.pdfHref"
              :aria-label="t('INVOICES.ARIA_DOWNLOAD')"
              variant="text"
              disable-visited
              @click="trackEventInvoiceClicked()"
            >
              <span>{{ t("INVOICES.DOWNLOAD") }}</span>
              <telia-icon name="download" slot="left" size="sm"></telia-icon>
            </telia-link>

            <telia-button
              :aria-label="t('INVOICES.ARIA_PREVIEW')"
              variant="text"
              size="sm"
              @click="previewInvoice(invoice)"
            >
              <telia-icon name="doc" slot="left" size="sm"></telia-icon>
              {{ t("INVOICES.PREVIEW") }}
            </telia-button>
          </div>
        </div>
      </div>

      <module-empty-state v-if="status === STATE.NO_ITEMS">
        {{ t("INVOICES.NO_ITEMS") }}
      </module-empty-state>

      <telia-notification
        v-if="status === STATE.ERROR"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("INVOICES.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("INVOICES.ERROR.TITLE") }}
        </span>
      </telia-notification>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.invoice {
  &__container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__table {
    display: table;
    width: 100%;
  }

  &__table-row {
    display: table-row;
    text-decoration: none;
    position: relative;
    @include telia-color("black");

    &:not(&--header) {
      cursor: pointer;
    }

    &:not(&--header):not(:last-child) {
      .invoice__table-cell {
        @include telia-border("gray-200", "xs", "bottom");
      }
    }

    &--header {
      @include telia-bg-color("gray-50");
    }

    &-tools {
      width: 100%;
      position: absolute;
      left: 0;
      display: none;
      padding: $telia-spacing-12 $telia-spacing-16;
      background: white;
      @include telia-border("gray-200", "xs", "bottom");

      telia-link {
        margin-right: $telia-spacing-32;
        telia-icon {
          margin-right: $telia-spacing-8;
          margin-top: -0.3rem;
          vertical-align: middle;
        }
      }
      telia-button {
        position: relative;
        top: 0.3rem;
      }
    }

    &--active {
      @include telia-bg-color("purple-100");
      .invoice__table-cell--status:not(.invoice__table-cell--heading) {
        padding-bottom: 7.6rem;
      }

      .invoice__table-row-tools {
        top: 5rem;
        display: block;
      }
    }

    &:not(&--header) {
      &:hover,
      &:focus {
        @include telia-bg-color("purple-100");
      }
    }
  }

  &__table-cell {
    text-align: left;
    transition: all $telia-duration-100 $telia-ease-in-out;
    display: table-cell;
    padding: 1.4rem $telia-spacing-16;
    white-space: nowrap;

    &--amount {
      text-align: right !important;
      min-width: 10rem;
    }

    &--date {
      min-width: 10rem;
    }

    &--heading {
      font-weight: bold;
      padding: $telia-spacing-16;
      line-height: $telia-spacing-24;
    }
  }

  &__skeleton {
    &--row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $telia-spacing-16;
    }
    &--cell {
      width: 25%;
      height: $telia-spacing-20;
    }
    &--heading {
      @include telia-bg-color("gray-50");
    }
  }
}
</style>
