<script setup lang="ts">
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { ServiceWebsByTags, getServiceWebs } from "@telia/b2b-service-web-lib";
import { getServiceWebs as fetchServiceWebs } from "@telia/b2b-logged-in-service";
import { logError } from "@telia/b2x-logging";

import STATE from "../../state.enum";
import { translateSetup, translateMixin } from "./locales";
import SkeletonCards from "../../../shared/SkeletonLoaders/SkeletonCards.vue";
import { onBeforeMount, ref } from "vue";
import { useModule } from "../../../../composables/module";
import { trackNavigationInteraction } from "../../../../utils/trackGA4";

type ServiceWeb = ServiceWebsByTags[0];

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const serviceWebs = ref<string[]>([]);
const apps = ref<ServiceWeb[]>([]);

const t = translateMixin.methods.t;

onBeforeMount(() => {
  translateSetup(props.title, props.name);
  tryToFetchServiceWebs();
});

const { refreshTheLayout, isLoading, status, moduleContainer, Module, ModuleEmptyState } =
  useModule(props, emit);

const trackEventApplicationClicked = (title: string) => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.APP_LINK_CLICK,
    title
  );
};

const tryToFetchServiceWebs = async () => {
  try {
    serviceWebs.value = (await fetchServiceWebs()) ?? [];
    apps.value = getServiceWebs(serviceWebs.value).filter(
      (serviceWeb) => !serviceWeb.id.includes("QLIK") && !serviceWeb.id.includes("MNC_REPORTING")
    );

    refreshTheLayout();

    status.value = apps.value.length ? STATE.SUCCESS : STATE.NO_ITEMS;
  } catch {
    logError("b2b-dashboard", "Failed to fetch the customer servicewebs in My applications module");

    status.value = STATE.ERROR;
  }
};

const onApplicationClick = (event: Event, application: ServiceWeb) => {
  if (application.id === "SURFMANAGER") {
    event.preventDefault();

    event.target?.dispatchEvent(
      new CustomEvent("b2b-navigation:show-surfmanager-modal", {
        bubbles: true,
        composed: true,
      })
    );
  }

  trackEventApplicationClicked(application.title);
  trackNavigationInteraction(application.title, "click");
};
</script>

<template>
  <Module
    id="MyApplications"
    :title="t('MY_APPLICATIONS.MODULE_TITLE')"
    :loading="isLoading"
    :padded-content="!isLoading"
    @module-removed="emit('module-removed', $event)"
  >
    <div class="apps-container" ref="moduleContainer">
      <skeleton-cards v-if="status === STATE.LOADING" :number-of-cards="2" />
      <telia-notification
        v-if="status === STATE.ERROR"
        t-id="general-error"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("MY_APPLICATIONS.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("MY_APPLICATIONS.ERROR.TITLE") }}
        </span>
      </telia-notification>

      <module-empty-state v-else-if="status === STATE.NO_ITEMS">
        {{ t("MY_APPLICATIONS.NO_ITEMS") }}
      </module-empty-state>

      <b2x-link-card
        v-else
        data-testid="app-item"
        v-for="app in apps"
        :key="app.id"
        :href="app.url"
        :target="app.openInNew ? '_blank' : undefined"
        :heading="app.title"
        @click="onApplicationClick($event, app)"
        :external="app.openInNew"
      >
        <span slot="body">{{ app.description }}</span>
      </b2x-link-card>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
.apps-container {
  min-height: 12.9rem;
}
</style>
