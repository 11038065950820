import { ref } from "vue";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";

const scopeId = ref("");

export const useScope = () => {
  const getScopeDetails = async () => {
    scopeId.value = await getScopeIdOrThrow();
  };

  return { getScopeDetails, scopeId };
};
