import { useScope } from "../composables/scope";

const { scopeId } = useScope();

export const setSelectedOrganisationToLocalStorage = (tscid: string) => {
  window.localStorage.setItem(`selectedOrganisation-${scopeId.value}`, tscid);
};

export const getSelectedOrganisationFromLocalStorage = () => {
  return window.localStorage.getItem(`selectedOrganisation-${scopeId.value}`);
};
