<script setup lang="ts">
import { onBeforeMount, ref, withDefaults, onBeforeUnmount, computed } from "vue";
import { logError } from "@telia/b2x-logging";
import { useDebounceFn } from "@vueuse/core";
import { getSearchResults } from "@telia/b2b-search-framework";
import { corpInstalledbaseSearch } from "@telia/b2b-rest-client";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { translateSetup, translateMixin } from "./locales";
import STATE from "../../state.enum";
import { useModule } from "../../../../composables/module";

import PukFinder from "./PukFinder.vue";

interface Option extends corpInstalledbaseSearch.SubscriptionUI {
  placeholder?: boolean;
}

const t = translateMixin.methods.t;

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const { hasMultipleOrganisations, Module } = useModule(props, emit);

const pukModule = ref();
const status = ref(STATE.RESET);
const searchDigitLimit = ref(3);
const search = ref("");
const options = ref<Option[]>([]);
const selectedOption = ref<Option>();
const searchIsFocused = ref(false);

const displayedOptions = computed(() => {
  if (statusIs(STATE.LOADING)) {
    return [];
  }

  return options.value.map((option) =>
    `${getSearchOptionNumber(option)} ${option.reference || ""}`.trim()
  );
});

onBeforeMount(() => {
  translateSetup(props.title, props.name);
});

onBeforeUnmount(() => {
  window.removeEventListener("click", onSearchBlur);
});

const getSearchOptionNumber = (option) => {
  if (option.mobileConnection) {
    return `${option.title} / ${option.mobileConnection}`;
  }

  return option.title;
};

const statusIs = (state: STATE) => {
  return status.value === state;
};

const searchSubscriptions = useDebounceFn(async (event: CustomEvent) => {
  search.value = event.detail;

  if (search.value !== "" && search.value.length >= searchDigitLimit.value) {
    status.value = STATE.LOADING;
    selectedOption.value = undefined;

    analytics.trackEvent(
      analytics.category.START_PAGE_MODULE,
      analytics.action.INITIATED,
      "Sökning på PUK"
    );

    try {
      const searchResults = await getSearchResults(
        ["MOBILE_VOICE", "MOBILE_BROADBAND", "FUNCTION_MOBILE", "FUNCTION_MOBILE_EXT"],
        props.scopeId,
        search.value
      );

      options.value = searchResults?.subscriptions?.length
        ? searchResults.subscriptions
        : [{ title: t("PUK.NO_RESULTS"), placeholder: true }];

      status.value = STATE.SUCCESS;
    } catch (error) {
      logError("b2b-dashboard", "Failed to get puk search results in the Puk module");

      status.value = STATE.ERROR;
      options.value = [];
    } finally {
      props.refreshLayout();
    }
  } else {
    status.value = STATE.RESET;
    options.value = [];
  }
}, 300);

const selectOption = (option: Option) => {
  if (option.placeholder) return;
  selectedOption.value = option;
  search.value = selectedOption?.value?.title || "";
  options.value = [];
  status.value = STATE.SUCCESS;

  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.SELECTED,
    "Mobilnummer valt"
  );
};

const resetSearch = () => {
  options.value = [];
  search.value = "";
  status.value = STATE.RESET;
  selectedOption.value = undefined;
};

const onSearchBlur = (event: Event) => {
  let clickOutside = !pukModule.value.$el.contains(event.target);

  if (clickOutside) {
    searchIsFocused.value = false;

    window.removeEventListener("click", onSearchBlur);
  }
};

const onSearchFocus = () => {
  searchIsFocused.value = true;
  window.addEventListener("click", onSearchBlur);
};

const onSelectSuggestion = (event) => {
  const optionIndex = displayedOptions.value.findIndex((option) => event.detail === option);
  selectOption(options.value[optionIndex]);
};
</script>

<template>
  <Module
    id="Puk"
    :title="t('PUK.MODULE_TITLE')"
    :subtitle="hasMultipleOrganisations ? t('PUK.MODULE_SUBTITLE') : undefined"
    :class="{ 'active-search': searchIsFocused }"
    ref="pukModule"
    @module-removed="emit('module-removed', $event)"
  >
    <!-- Subscription search -->
    <div class="subscription-finder" ref="moduleContainer">
      <div class="subscription-finder__search">
        <b2x-input-suggestions
          t-id="puk-search-input"
          data-web-analytics-label="Subscription for PUK"
          :label="t('PUK.SEARCH_LABEL')"
          :suggestions="JSON.stringify(displayedOptions)"
          :loading="statusIs(STATE.LOADING)"
          :value="search"
          @focus="onSearchFocus"
          @inputValue="searchSubscriptions"
          @suggestionSelected="onSelectSuggestion"
        >
          <telia-icon
            v-if="statusIs(STATE.RESET)"
            slot="right-icon"
            name="search"
            size="sm"
          ></telia-icon>
        </b2x-input-suggestions>

        <telia-button
          v-if="search !== '' && statusIs(STATE.SUCCESS)"
          slot="right-icon"
          class="subscription-finder__reset-button"
          size="sm"
          variant="text"
          @click="resetSearch()"
        >
          <small v-html="t('PUK.RESET')"></small>
          <telia-icon slot="right" name="close" size="sm"></telia-icon>
        </telia-button>
      </div>
    </div>

    <div class="puk__module-alert-wrapper" v-if="statusIs(STATE.ERROR)">
      <telia-notification
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("PUK.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("PUK.ERROR.TITLE") }}
        </span>
      </telia-notification>
    </div>

    <div class="puk__module-alert-wrapper" v-if="statusIs(STATE.HIT_LIMIT_EXCEEDED)">
      <telia-notification
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("PUK.HIT_LIMIT_EXCEEDED.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("PUK.HIT_LIMIT_EXCEEDED.TITLE") }}
        </span>
      </telia-notification>
    </div>

    <PukFinder :number="selectedOption" :scopeId="scopeId" @status="refreshLayout" />
  </Module>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.puk__module-alert-wrapper {
  margin-top: $telia-spacing-12;
}
.active-search {
  z-index: 2;
}
.subscription-finder {
  position: relative;
  z-index: 1;

  &__search {
    position: relative;
  }

  &__reset-button {
    position: absolute;
    right: $telia-spacing-8;
    top: $telia-spacing-32;
    margin-top: calc(#{$telia-spacing-4} * -1);
  }
}
</style>
