import { ref } from "vue";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { logError } from "@telia/b2x-logging";

import type { UnwrappedPromise } from "../../typings/unwrapped-promise";
import {
  getSelectedOrganisationFromLocalStorage,
  setSelectedOrganisationToLocalStorage,
} from "../utils/localStorage";

export enum STATUS {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

const ALL_ORGANISATIONS = "ALL";

type Organisation = UnwrappedPromise<ReturnType<typeof getOrganizations>>[number];

const organisations = ref<Organisation[]>([]);
const selectedOrganisation = ref("");
const status = ref(STATUS.LOADING);

export const useOrganisations = () => {
  const setStatusToSuccess = (): void => {
    status.value = STATUS.SUCCESS;
  };
  const setStatusToError = (): void => {
    status.value = STATUS.ERROR;
  };
  const setStatusToLoading = (): void => {
    status.value = STATUS.LOADING;
  };

  const fetchOrganisations = async (): Promise<void> => {
    try {
      const _organisations = await getOrganizations();
      const sortedOrganisations = _organisations.sort((a: Organisation, b: Organisation) =>
        (a.name || "").localeCompare(b.name || "")
      );

      organisations.value = sortedOrganisations;

      const organisationLocal = hasSavedOrganisation();

      const selectedOrganisation =
        organisationLocal && organisationLocal !== ALL_ORGANISATIONS
          ? organisationLocal
          : sortedOrganisations[0].tscid ?? "";
      setSelectedOrganisation(selectedOrganisation);

      status.value = STATUS.SUCCESS;
    } catch {
      organisations.value = [];
      status.value = STATUS.ERROR;

      logError("b2b-dashboard", "Failed to get the user organisations");
    }
  };

  const hasSavedOrganisation = () => {
    const organisationLocal = getSelectedOrganisationFromLocalStorage();
    const organisationLocalIsAvailable = organisations.value.some(
      (organisation) => organisation.tscid === organisationLocal
    );
    return organisationLocalIsAvailable ? organisationLocal : organisations.value[0].tscid;
  };

  const setSelectedOrganisation = (tscid: string): void => {
    selectedOrganisation.value = tscid;
    setSelectedOrganisationToLocalStorage(tscid);
  };

  return {
    setStatusToSuccess,
    setStatusToError,
    setStatusToLoading,
    fetchOrganisations,
    setSelectedOrganisation,
    hasSavedOrganisation,
    organisations,
    selectedOrganisation,
    status,
  };
};
