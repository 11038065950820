import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

enum Feature {
  PUK_SHOW_MISSING_NAME = "puk-show-missing-name",
  RECOMMENDATIONS = "show-recommendations",
  MY_BENEFITS = "my-benefits",
  DATANET = "datanet",
  ORDER_M2M = "order-m2m",
}

const toggles: Record<Feature, boolean> = {
  [Feature.PUK_SHOW_MISSING_NAME]: false,
  [Feature.RECOMMENDATIONS]: false,
  [Feature.MY_BENEFITS]: true,
  [Feature.DATANET]: false,
  [Feature.ORDER_M2M]: true,
};

export const pukShowMissingNameEnabled = (): boolean => isEnabled(Feature.PUK_SHOW_MISSING_NAME);
export const recommendationsEnabled = (): boolean => isEnabled(Feature.RECOMMENDATIONS);
export const myBenefitsEnabled = (): boolean => isEnabled(Feature.MY_BENEFITS);
export const dataNetEnabled = (): boolean => isEnabled(Feature.DATANET);
export const orderM2mEnabled = (): boolean => isEnabled(Feature.ORDER_M2M);

function isEnabled(toggleName: Feature): boolean {
  return featureToggleIsEnabled(toggleName, toggles);
}
