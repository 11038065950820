import sv from "./sv.json";
import en from "./en.json";
import { addTranslations, vueTranslateMixin, currentLanguage } from "@telia/b2b-i18n";

export const translateMixin = vueTranslateMixin;
export const translateSetup = (title, name) => {
  if (title && name) {
    sv[name].MODULE_TITLE = title.sv.MODULE_TITLE;
    en[name].MODULE_TITLE = title.en.MODULE_TITLE;
  }
  addTranslations({ sv, en });
};
export const currentLang = currentLanguage();
