<script setup lang="ts">
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { currentLanguage } from "@telia/b2b-i18n";

import formatCurrencyHelper from "../../../../helpers/currency.js";
import LinkCard from "../../../shared/LinkCard.vue";
import { translateMixin } from "./locales";
import simcardImage from "./img/simcard.png";

type Product = {
  id: string;
  name: string;
  recurringFee: string;
  productCode: string;
};

const props = withDefaults(
  defineProps<{
    scopeId: string;
    products: Product[];
    selectedOrganisation: string;
    inTab?: boolean;
  }>(),
  {
    inTab: false,
  }
);

const t = translateMixin.methods.t;

const getProductHref = (product: Product) => {
  return `/foretag/mybusiness/${props.scopeId}/bestall/${props.selectedOrganisation}/mobilabonnemang/produkt/${product.productCode}`;
};

const formatCurrency = (amount: number) => {
  return formatCurrencyHelper(currentLanguage(), amount);
};

const trackEventProductClicked = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Beställ modul produkt klickad"
  );
};
</script>

<template>
  <div t-id="productlist" class="products-list" :class="{ 'products-list--in-tab': inTab }">
    <LinkCard
      :key="product.id"
      v-for="product in products"
      class="product"
      :href="getProductHref(product)"
      :title="product.name"
      @click="trackEventProductClicked()"
    >
      <template #left>
        <div class="product__img">
          <img :src="simcardImage" :alt="product.name" />
        </div>
      </template>

      {{
        t("ORDER_NOW_NFA.PRODUCTS_PRICE_RANGE", {
          FEE: formatCurrency(parseFloat(product.recurringFee)),
        })
      }}
    </LinkCard>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.products-list {
  margin: 0 $telia-spacing-24;

  &--in-tab {
    margin: $telia-spacing-8 0 0;
  }
}

.product {
  &__img {
    width: $telia-spacing-48;
    height: $telia-spacing-48;
    display: flex;
    img {
      align-self: center;
      color: $telia-purple-500;
      width: 100%;
    }
  }
}
</style>
