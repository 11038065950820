import sv from "./sv.json";
import en from "./en.json";
import {
  addTranslations,
  vueTranslateMixin,
  currentLanguage,
  addTranslation,
  currentLocale,
} from "@telia/b2b-i18n";
import { fetchMicrocopy } from "@telia/b2x-microcopy";

export const translateMixin = vueTranslateMixin;
export const currentLang = currentLanguage();
export const translateSetup = async (identifiers) => {
  addTranslations({ sv, en });

  if (identifiers && identifiers.length > 0) {
    const locale = currentLocale();
    const translations = await fetchMicrocopy(locale, identifiers);
    addTranslation(translations, locale);
  }
};
