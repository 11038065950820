<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from "vue";

import Module from "../../Module.vue";

import { useScope } from "../../../../composables/scope";

import { client, BENEFIT_INFORMATION } from "./api";
import { translateSetup, translateMixin } from "./locales";

import STATE from "../../state.enum";

const props = defineProps<{
  title: object;
  name: string;
}>();

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const t = translateMixin.methods.t;
const contentData = ref();

const { scopeId } = useScope();

onBeforeMount(() => {
  translateSetup(props.title, props.name);
});

onMounted(async () => {
  const data = await client.request(BENEFIT_INFORMATION);
  contentData.value = data?.teaserWrapper?.teaserList[0];
});
</script>

<template>
  <Module
    id="BusinessPlus"
    :title="t('MY_BENEFITS.MODULE_TITLE')"
    :link-title="t('MY_BENEFITS.LINK_TEXT')"
    :link-href="`/foretag/mybusiness/${scopeId}/bestall/formaner`"
    @module-removed="emit('module-removed', $event)"
  >
    <div data-dont-collect t-id="benefits-info" ref="moduleContainer">
      <telia-heading tag="h6" variant="subsection-100">
        <strong v-if="contentData">{{ contentData.title }}</strong>
      </telia-heading>
      <b2x-rich-text
        v-if="contentData && contentData.richPreamble"
        :json="contentData.richPreamble"
      />
    </div>
  </Module>
</template>
