<script setup lang="ts">
import { computed, watch } from "vue";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { logError } from "@telia/b2x-logging";
import { useOrganisations } from "../../../composables/organisations";
import { useScope } from "../../../composables/scope";

const emit = defineEmits<{
  (e: "error"): void;
  (e: "selectedOrganisation", arg1: string): void;
}>();

const { selectedOrganisation, organisations, status, fetchOrganisations, setSelectedOrganisation } =
  useOrganisations();
const { scopeId } = useScope();

const shouldShowDropdown = computed(() => {
  return (
    status.value === "SUCCESS" && userOrganisations.value.length > 1 && !!selectedOrganisation.value
  );
});

const userOrganisations = computed(() => {
  if (!organisations.value?.length) return [];

  return organisations.value.map((org) => ({
    label: org.name,
    value: org.tscid,
  }));
});

const updateSelectedOrganisation = (tscid: string) => {
  setSelectedOrganisation(tscid);
  trackEventOrganisationChanged();
};

const onScopeIdChange = async () => {
  if (scopeId.value !== undefined) {
    try {
      await fetchOrganisations();
    } catch {
      logError("b2b-dashboard", "Failed to fetch organisations to organisation selector");
      emit("error");
    }
  }
};

const onSelectedOrganisationChange = (tscid: string) => {
  emit("selectedOrganisation", tscid);
};

const trackEventOrganisationChanged = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Bytt vald organisation"
  );
};

watch(() => scopeId.value, onScopeIdChange);
watch(() => selectedOrganisation.value, onSelectedOrganisationChange);
</script>

<template>
  <div class="organisation-selector">
    <div v-if="shouldShowDropdown" class="organisation-selector__wrapper">
      <b2x-inline-dropdown
        class="organisation-selector__dropdown"
        :options="JSON.stringify(userOrganisations)"
        :value="selectedOrganisation"
        heading-element="telia-p"
        heading-variant="paragraph-100"
        icon-size="sm"
        @optionSelected="updateSelectedOrganisation($event.detail)"
      ></b2x-inline-dropdown>
    </div>
    <telia-skeleton
      v-else-if="status === 'LOADING'"
      class="organisation-selector__skeleton"
    ></telia-skeleton>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../styles/voca";

.organisation-selector {
  min-width: 40rem;
  margin-left: calc(#{$telia-spacing-8} * -1);

  @media screen and (max-width: 600px) {
    min-width: 30rem;
  }

  &__wrapper {
    margin-bottom: 0;
    line-height: normal;
  }

  &__dropdown {
    --inline-dropdown-min-width: 40rem;
  }

  &__skeleton {
    width: 30rem;
    height: $telia-spacing-24;
    margin-bottom: $telia-spacing-16;
  }
}
</style>
