<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";

import { logError } from "@telia/b2x-logging";
import { corpOrderHistory } from "@telia/b2b-rest-client";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { useScope } from "../../../../composables/scope";
import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import { translateSetup, translateMixin } from "./locales";

import ListItem from "../../../shared/ListItem.vue";

import STATE from "../../state.enum";

type WidgetOrderUI = {
  description: string;
  orderId: string;
  promisedDeliveryDate: string;
  tscId: string;
  uid: string;
};

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const orders = ref<WidgetOrderUI[]>([]);
const skeletonOrdersCount = ref(2);
const t = translateMixin.methods.t;

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
  await getOrders();
});

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  moduleLoader,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

const { selectedOrganisation } = useOrganisations();

const { scopeId } = useScope();

const shouldDisableFooter = computed(() => {
  return status.value === STATE.LOADING || status.value === STATE.ERROR;
});

const getOrders = async () => {
  try {
    if (!selectedOrganisation.value) {
      status.value = STATE.PRECONDITION_NOT_MET;
      return;
    }

    status.value = STATE.LOADING;

    const response = await corpOrderHistory.WidgetControllerService.getOrdersForWidgetUsingGet(
      scopeId.value,
      selectedOrganisation.value,
      3,
      ["ONGOING", "INCOMPLETE"] as unknown as "INCOMPLETE" | "ONGOING"
    );
    orders.value =
      response.map((order) => ({
        description: order.description ?? "",
        orderId: order.orderId ?? "",
        promisedDeliveryDate: order.promisedDeliveryDate ?? "",
        tscId: order.tscId ?? "",
        uid: order.uid ?? "",
      })) ?? [];

    status.value = orders.value.length ? STATE.SUCCESS : STATE.NO_ITEMS;
    skeletonOrdersCount.value = Math.max(orders.value.length, 1);
  } catch (error) {
    logError("b2b-dashboard", "Failed to fetch customer orders in the Orders module");

    status.value = STATE.ERROR;
  } finally {
    await refreshTheLayout();
  }
};

const trackEventOrderClicked = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Beställning klickad"
  );
};

watch(() => selectedOrganisation.value, getOrders);
</script>

<template>
  <Module
    id="Orders"
    :title="t('ORDERS.MODULE_TITLE')"
    :link-title="t('ORDERS.LINK_ALL_ORDERS')"
    :link-href="`/foretag/mybusiness/${scopeId}/bestall/alla-bestallningar`"
    @module-removed="emit('module-removed', $event)"
    :footerDisabled="shouldDisableFooter"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :loading="isLoading"
  >
    <div class="order-container" ref="moduleContainer">
      <div v-if="isLoading" ref="moduleLoader" key="moduleLoader">
        <div class="orders__skeleton-wrapper" v-for="index in skeletonOrdersCount" :key="index">
          <telia-skeleton class="orders__skeleton orders__skeleton--title"></telia-skeleton>
          <telia-skeleton class="orders__skeleton orders__skeleton--body"></telia-skeleton>
        </div>
      </div>

      <telia-notification
        v-if="status === STATE.ERROR"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDERS.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDERS.ERROR.TITLE") }}
        </span>
      </telia-notification>

      <module-empty-state v-if="status === STATE.NO_ITEMS">
        {{ t("ORDERS.NO_ITEMS") }}
      </module-empty-state>

      <div class="orders-list" v-if="status === STATE.SUCCESS">
        <ListItem
          v-for="order in orders"
          :key="order.uid"
          :href="`/foretag/mybusiness/${scopeId}/bestall/alla-bestallningar?OrderID=${order.uid}`"
          :title="order.orderId"
          :right-title="order.promisedDeliveryDate"
          ga-text="Order details"
          @click="trackEventOrderClicked()"
        >
          {{ order.description }}
        </ListItem>
      </div>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.order-container {
  min-height: 12.9rem;
}

.orders {
  &__skeleton-wrapper {
    padding: $telia-spacing-12 0;

    &:not(:last-child) {
      @include telia-border("gray-200", "xs", "bottom");
    }
  }

  &__skeleton {
    height: $telia-spacing-20;

    &--title {
      width: 100%;
    }

    &--body {
      width: $telia-spacing-80;
      margin-top: $telia-spacing-8;
    }
  }
}
</style>
