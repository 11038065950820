<script setup lang="ts">
import { onBeforeMount, computed, ref } from "vue";

import { getLatestReleaseNote, getInnerHtml } from "@telia/b2b-release-notes";
import type { BadgeVariantType } from "@teliads/components/dist/types/types/BadgeVariant";

import { useModule } from "../../../../composables/module";

import { translateSetup, translateMixin, currentLang } from "./locales";

import STATE from "../../state.enum";

const props = defineProps<{
  title: object;
  name: string;
  selectedOrganisation: string;
  scopeId: string;
  refreshLayout: () => void;
}>();

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const t = translateMixin.methods.t;
const { refreshTheLayout, moduleContainer, Module } = useModule(props, emit);
const releaseNote = ref(getLatestReleaseNote(currentLang));

onBeforeMount(async () => {
  translateSetup(props.title, props.name);
  await refreshTheLayout();
});

const href = computed(() => `/foretag/mybusiness/${props.scopeId}/release-notes`);

const badgeText = computed(() => t(`RELEASE_NOTE.type.${releaseNote.value?.data.type}`));

const getVariant = (type: string | undefined): BadgeVariantType => {
  switch (type) {
    case "release":
      return "special-offer";
    case "improvement":
      return "information";
    default:
      return "neutral";
  }
};
</script>

<template>
  <Module
    id="ReleaseNotes"
    :title="t('RELEASE_NOTE.MODULE_TITLE')"
    :padded-content="true"
    :link-title="t('RELEASE_NOTE.LINK')"
    :link-href="href"
    @module-removed="emit('module-removed', $event)"
  >
    <div :id="moduleContainer" class="release-note">
      <time data-testid="date" :dateTime="releaseNote?.data.date">{{
        releaseNote?.data.date
      }}</time>

      <telia-badge :variant="getVariant(releaseNote?.data.type)">{{ badgeText }}</telia-badge>
      <div
        t-id="release-note-content"
        class="content"
        v-html="getInnerHtml(releaseNote?.html ?? '')"
      ></div>
    </div>
  </Module>
</template>

<style scoped lang="scss">
@import "~@teliads/components/foundations/typography/mixins.scss";

.release-note {
  telia-badge {
    display: block;
    margin-bottom: var(--purpur-spacing-200);
  }

  :deep(h2) {
    margin-bottom: var(--purpur-spacing-200);
    @include telia-paragraph-200;
    @include telia-typography("teliasans", "medium");

    text-wrap: balance;
  }

  :deep(h3) {
    @include telia-title-100;
    margin-bottom: var(--purpur-spacing-100);
    text-wrap: balance;
  }

  :deep(p) {
    @include telia-paragraph-100;
    margin-bottom: var(--purpur-spacing-200);
  }

  time {
    @include telia-subsection-100;
    display: block;
    margin-bottom: var(--purpur-spacing-200);
  }

  :deep(li) {
    @include telia-paragraph-100;
    margin-left: var(--purpur-spacing-200);
    margin-bottom: var(--purpur-spacing-100);
  }

  :deep(ul) {
    margin-bottom: var(--purpur-spacing-200);
    padding-left: var(--purpur-spacing-200);
  }

  :deep(img) {
    max-width: 100%;
  }

  :deep(.content) {
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: var(--purpur-spacing-200);
  }

  :deep(a) {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    gap: var(--purpur-spacing-50);
    text-decoration: underline;
    color: var(--purpur-color-text-interactive-primary-active);

    &:hover {
      background-color: var(--purpur-color-background-interactive-transparent-hover);
    }

    &:active {
      background-color: var(--purpur-color-background-interactive-transparent-active);
    }
  }
}
</style>
