import { hasPermission } from "@telia/b2b-logged-in-service";
import { corpAnalyticsService } from "@telia/b2b-rest-client";
import { logError } from "@telia/b2x-logging";

export type ReportCategory = {
  name: string;
  icon: string;
  reports: Report[];
};

export type Report = {
  id: string;
  title: string;
  description: string;
  link: string;
  permission: string | null;
  sessionType?: SessionType;
  isVisible: (serviceWebs: string[]) => Promise<boolean>;
};

export type SessionType =
  | "CONFIDENTIAL"
  | "DEFAULT"
  | "FINANCIAL"
  | "QUALITY"
  | "USAGE"
  | undefined;

export const getAllReportCategories = (scopeId: string): ReportCategory[] => [
  {
    name: "STATISTICS_AND_REPORTS.REPORT_CATEGORIES.ENTERPRISE_ANALYTICS",
    icon: "bar-chart",
    reports: [
      {
        id: "MOBILE_USAGE",
        title: "{STATISTICS_AND_REPORTS__REPORTS__MOBILE_USAGE__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__MOBILE_USAGE__DESCRIPTION}",
        link: "/qlik",
        permission: "ANALYTICS_QLIK_USAGE",
        sessionType: "USAGE",
        isVisible: async (serviceWebs) => serviceWebs.includes("ANALYTICS_QLIK_USAGE"),
      },
      {
        id: "FINANCIAL_INFO",
        title: "{STATISTICS_AND_REPORTS__REPORTS__FINANCIAL_INFO__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__FINANCIAL_INFO__DESCRIPTION}",
        link: "/financial-info",
        permission: "ANALYTICS_QLIK_FINANCIAL",
        sessionType: "FINANCIAL",
        isVisible: async (serviceWebs) => serviceWebs.includes("ANALYTICS_QLIK_FINANCIAL"),
      },
      {
        id: "CONFIDENTIAL",
        title: "{STATISTICS_AND_REPORTS__REPORTS__CONFIDENTIAL__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__CONFIDENTIAL__DESCRIPTION}",
        link: "/confidential",
        permission: "ANALYTICS_QLIK_CONFIDENTIAL",
        sessionType: "CONFIDENTIAL",
        isVisible: async (serviceWebs) => serviceWebs.includes("ANALYTICS_QLIK_CONFIDENTIAL"),
      },
      {
        id: "QUALITY",
        title: "{STATISTICS_AND_REPORTS__REPORTS__QUALITY__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__QUALITY__DESCRIPTION}",
        link: "/quality",
        permission: "ANALYTICS_QLIK_QUALITY",
        sessionType: "QUALITY",
        isVisible: async (serviceWebs) => serviceWebs.includes("ANALYTICS_QLIK_QUALITY"),
      },
    ],
  },
  {
    name: "STATISTICS_AND_REPORTS.REPORT_CATEGORIES.REPORTS",
    icon: "report",
    reports: [
      {
        id: "MNC_REPORTING",
        title: "{STATISTICS_AND_REPORTS__REPORTS__MNC_REPORTING__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__MNC_REPORTING__DESCRIPTION}",
        link: "https://identity.teliacompany.com/saml/mnc/idpinit/auth?authenticator=mnc-se",
        permission: "MNC_REPORTING",
        isVisible: () => hasPermission("MNC_REPORTING"),
      },
    ],
  },
  {
    name: "STATISTICS_AND_REPORTS.REPORT_CATEGORIES.DATACOM",
    icon: "internet",
    reports: [
      {
        id: "DATACOM_SLA",
        title: "{STATISTICS_AND_REPORTS__REPORTS__DATACOM_SLA__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__DATACOM_SLA__DESCRIPTION}",
        link: `/foretag/mybusiness/${scopeId}/support/rapporter/tillganglighet-av-tjanster`,
        permission: null,
        isVisible: async () => true,
      },
      {
        id: "DATACOM_NETWORKS",
        title: "{STATISTICS_AND_REPORTS__REPORTS__DATACOM_NETWORKS__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__DATACOM_NETWORKS__DESCRIPTION}",
        link: `/foretag/mybusiness/${scopeId}/support/rapporter/natverkstillganglighet`,
        permission: null,
        isVisible: async () => true,
      },
    ],
  },
  {
    name: "STATISTICS_AND_REPORTS.REPORT_CATEGORIES.SUPPORT",
    icon: "summary",
    reports: [
      {
        id: "SUPPORT_SLA",
        title: "{STATISTICS_AND_REPORTS__REPORTS__SUPPORT_SLA__TITLE}",
        description: "{STATISTICS_AND_REPORTS__REPORTS__SUPPORT_SLA__DESCRIPTION}",
        link: `/foretag/mybusiness/${scopeId}/support/rapporter/sla-rapporter`,
        permission: null,
        isVisible: async (serviceWebs) => !serviceWebs.includes("ANALYTICS_QLIK_QUALITY"),
      },
    ],
  },
];

export const getFilteredReportCategories = async (
  scopeId: string,
  serviceWebs: string[]
): Promise<ReportCategory[]> => {
  type ResolvedReport = { visible: boolean } & Report;

  const allCategories = getAllReportCategories(scopeId);

  const categories: ReportCategory[] = [];
  allCategories.forEach(async (category) => {
    const reports = (
      await Promise.all(
        category.reports.map(async (report) => ({
          ...report,
          visible: await report.isVisible(serviceWebs),
        }))
      )
    ).filter((report: ResolvedReport) => report.visible);

    if (reports.length) {
      categories.push({ ...category, reports });
    }
  });

  return categories;
};

export const openQlik = async (
  scopeId: string,
  sessionType: SessionType
): Promise<
  ReturnType<typeof corpAnalyticsService.QlikSessionControllerService.createQlikSessionUsingPost>
> => {
  try {
    const qlikResponse = await corpAnalyticsService.QlikSessionControllerService.createQlikSessionUsingPost(
      scopeId,
      sessionType
    );

    return qlikResponse;
  } catch (error: unknown) {
    logError("b2b-dashboard", "Failed to create Qlik session in the Statistics and reports module");

    return formatError(error as Record<string, string>);
  }
};

const formatError = (err: Record<string, string>) => {
  const errors = qlikErrors();
  const error = errors[err?.errorKey] || errors.DEFAULT;

  return Promise.reject(error);
};

const qlikErrors = () => ({
  ACCESS_DENIED_NO_ORGANISATION: "STATISTICS_AND_REPORTS.SESSION.NO_ORGANISATION_ERROR",
  ACCESS_DENIED_INVALID_RESTRICTIONS: "STATISTICS_AND_REPORTS.SESSION.INVALID_RESTRICTIONS_ERROR",
  DEFAULT: "STATISTICS_AND_REPORTS.SESSION.ERROR",
});
