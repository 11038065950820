<script setup lang="ts">
import { translateMixin } from "../locales";
import { trackStartPageConfiguration } from "../../../utils/trackGA4";

const t = translateMixin.methods.t;

const props = defineProps<{
  icon: string;
  isNew: boolean;
  name: string;
  selected: boolean;
}>();

const emit = defineEmits<{
  (e: "select", id: MouseEvent): void;
}>();

const handleButtonClicked = (event: MouseEvent) => {
  trackStartPageConfiguration(props.name, props.selected ? "hide" : "show");
  emit("select", event);
};
</script>

<template>
  <button
    class="module-item"
    :class="{
      'module-item--is-new': isNew,
      'module-item--selected': selected,
    }"
    @click="handleButtonClicked"
    role="switch"
    :aria-checked="selected"
  >
    <div class="module-item__icon">
      <telia-icon :name="icon" size="md"></telia-icon>
    </div>

    <div>
      <telia-p class="module-item__name" variant="paragraph-100">
        <strong>{{ name }}</strong>
      </telia-p>
    </div>

    <telia-badge v-if="isNew" class="module-item__badge" variant="information">
      {{ t("DASHBOARD.NEW_MODULE_BADGE") }}
    </telia-badge>

    <div class="module-item__status">
      <telia-icon
        class="module-item__status-icon module-item__status-icon--add"
        name="plus"
        size="sm"
      ></telia-icon>
      <telia-icon
        class="module-item__status-icon module-item__status-icon--remove"
        name="minus"
        size="sm"
      ></telia-icon>
      <telia-icon
        class="module-item__status-icon module-item__status-icon--selected"
        name="checkmark"
        size="sm"
      ></telia-icon>
    </div>
  </button>
</template>

<style lang="scss" scoped>
@import "../../../styles/voca";

.module-item {
  $block: &;

  $bg-color: #fbfbfb;

  position: relative;
  width: 100%;
  display: grid;
  padding: $telia-spacing-12 $telia-spacing-16;
  margin-bottom: $telia-spacing-24;
  grid-template-columns: $telia-spacing-24 auto $telia-spacing-16;
  column-gap: $telia-spacing-16;
  align-items: center;
  text-align: left;
  text-decoration: none;
  background-color: $bg-color;
  @include telia-color("black");
  @include telia-border("transparent", "none");
  @include telia-border-radius("default");
  outline-color: $telia-purple-500;
  box-shadow: 0 0 0 $telia-border-width-1 $telia-gray-200;
  transition: all $telia-duration-150 $telia-ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 $telia-border-width-2 $telia-gray-400;

    #{$block}__status-icon {
      &--add {
        opacity: 1;
      }
    }
  }

  &:focus-visible {
    #{$block}__name {
      text-decoration: underline;
      @include telia-color("purple-700");
    }
  }

  &:focus {
    outline: none;
  }

  &--is-new {
    grid-template-columns: $telia-spacing-24 auto $telia-spacing-48 $telia-spacing-16;
  }

  &--selected {
    box-shadow: 0 0 0 $telia-border-width-2 $telia-purple-500 !important;

    &:hover,
    &:focus-visible {
      #{$block}__status-icon {
        &--add {
          opacity: 0;
        }

        &--remove {
          opacity: 1;
        }

        &--selected {
          opacity: 0;
        }
      }
    }

    #{$block}__status-icon {
      &--remove,
      &--selected {
        @include telia-color("purple-500");
      }

      &--selected {
        opacity: 1;
      }
    }
  }

  &__icon {
    text-align: center;
    display: flex;
    align-items: center;
    @include telia-color("black");
  }

  &__status {
    position: relative;
    height: 100%;
  }

  &__status-icon {
    position: absolute;
    top: $telia-spacing-4;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity $telia-duration-100 $telia-ease-in-out;
  }
}
</style>
