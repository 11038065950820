<script setup lang="ts">
import { onBeforeMount, ref, watch } from "vue";
import { translateSetup, translateMixin } from "../locales";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { trackStartPageConfiguration } from "../../../utils/trackGA4";

onBeforeMount(() => {
  translateSetup();
});

const props = defineProps<{ title: string }>();
const emit = defineEmits(["remove-module"]);

const t = translateMixin.methods.t;
const editMenuOpen = ref(false);
const editPanel = ref();

const editMenuPressed = () => {
  editMenuOpen.value = !editMenuOpen.value;
};

const hideEditPanel = () => {
  if (!editPanel.value.matches(":focus-within")) {
    editMenuOpen.value = false;
  }
};

const hideModule = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.HIDE,
    `Dölj ${props.title} modul`
  );

  trackStartPageConfiguration(props.title, "hide");
  emit("remove-module");
  editMenuOpen.value = false;
};

const onEditMenuOpenChange = (isOpen: boolean) => {
  isOpen
    ? window.addEventListener("focusout", hideEditPanel)
    : window.removeEventListener("focusout", hideEditPanel);
};

watch(() => editMenuOpen.value, onEditMenuOpenChange);
</script>

<template>
  <div class="module__edit-panel" ref="editPanel">
    <button
      class="module__edit-button"
      ref="editButton"
      @click="editMenuPressed"
      tabindex="0"
      t-id="editPanelButton"
      :aria-label="t('MODULE.MODULE_SETTINGS')"
    >
      <telia-icon name="more-vertical" size="md"></telia-icon>
    </button>

    <transition name="fade">
      <div ref="editMenu" role="tooltip" class="module__edit-menu" v-show="editMenuOpen">
        <slot name="edit-menu">
          <div class="module__edit-list">
            <button class="link-disabled" disabled>
              <telia-icon name="filter" size="sm"></telia-icon>
              {{ t("MODULE.EDIT_SETTINGS") }}
            </button>

            <button @click="hideModule()" t-id="removeModule">
              <telia-icon name="password-invisible" size="sm"></telia-icon>
              {{ t("MODULE.EDIT_REMOVE") }}
            </button>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/voca";

.module {
  &__edit-panel {
    position: relative;
  }

  &__edit-button {
    width: $telia-spacing-48;
    height: $telia-spacing-48;
    padding: $telia-spacing-12 $telia-spacing-4;
    line-height: 3.6rem;
    border: none;
    @include telia-bg-color("transparent");
    vertical-align: middle;
    outline: none;
    cursor: pointer;
    margin-right: -1rem;
    @include telia-border-radius("full");

    &:focus-visible {
      @include telia-focus-outline();
    }

    &:hover {
      @include telia-color("purple-500");
      @include telia-bg-color("purple-100");
    }

    &:active {
      @include telia-bg-color("purple-200");
      @include telia-color("purple-500");
    }
  }

  &__edit-menu {
    @include telia-bg-color("white");
    @include telia-shadow("sm");
    @include telia-border("gray-300", "xs", "all");
    opacity: 1;
    position: absolute;
    right: 0;
    top: calc(100% + #{$telia-spacing-4});
    min-width: 170px;
    border-radius: $telia-border-radius-8;
    transform-origin: top right;
    transform: scale(1);
    cursor: default;
  }

  &__edit-list {
    display: flex;
    flex-direction: column;
    padding: $telia-spacing-8;
    margin: 0;

    button {
      display: flex;
      align-items: center;
      text-decoration: none;
      @include telia-color("black");
      font-size: 1.3rem;
      font-weight: normal;
      padding-left: $telia-spacing-16;
      padding-right: $telia-spacing-16;
      padding-top: $telia-spacing-12;
      padding-bottom: $telia-spacing-12;
      transition: color $telia-duration-100 $telia-ease-in-out;
      cursor: pointer;
      background: none;
      border: none;
      @include telia-border-radius("full");
      @include telia-static-type(1.6rem);

      .telia-icon {
        margin-right: $telia-spacing-8;
      }

      @include telia-bg-color("transparent");
      @include telia-border("white", "none");
      @include telia-color("red-600");

      &:hover {
        @include telia-bg-color("red-100");
        @include telia-color("red-600");
      }

      &:active {
        @include telia-bg-color("red-200");
        @include telia-color("red-600");
      }

      &:disabled {
        @include telia-color("gray-400");
        @include telia-bg-color("transparent");
      }

      &.link-disabled {
        @include telia-color("gray-500");
        user-select: none;
        cursor: not-allowed;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all $telia-duration-100 $telia-ease-in;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>
