<template>
  <b2x-onboarding
    :is-open="onboardingModalOpen"
    @closeModal="onCloseModal"
    @nextStepClick="trackOnboardingNextStep"
    @previousStepClick="trackOnboardingPreviousStep"
  >
    <b2x-onboarding-step
      :key="stepIndex"
      v-for="(step, stepIndex) in steps"
      :src="step"
      :heading="t(`ONBOARDING_MODAL.STEP_${stepIndex + 1}_TITLE`)"
      :description="t(`ONBOARDING_MODAL.STEP_${stepIndex + 1}_DESC`)"
      :alt="t(`ONBOARDING_MODAL.ALT_TEXT.STEP_${stepIndex + 1}`)"
    ></b2x-onboarding-step>
  </b2x-onboarding>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { usePage } from "../../composables/page";
import { onboardingVueMixin as onbardingAnalyticsMixin } from "@telia/b2b-web-analytics-wrapper";
import { translateMixin, translateSetup } from "./locales";

import onboarding_1 from "./img/onboarding_1.gif";
import onboarding_2 from "./img/onboarding_2.gif";
import onboarding_3 from "./img/onboarding_3.gif";
import onboarding_4 from "./img/onboarding_4.gif";
import onboarding_5 from "./img/onboarding_5.gif";

const { onboardingModalOpen, closeOnboardingModal } = usePage();

translateSetup();
const t = translateMixin.methods.t;

const steps = ref([onboarding_1, onboarding_2, onboarding_3, onboarding_4, onboarding_5]);

const trackOnboardingOpen = onbardingAnalyticsMixin.methods.trackOnboardingOpen;
const trackOnboardingClose = onbardingAnalyticsMixin.methods.trackOnboardingClose;
const trackOnboardingNextStep = onbardingAnalyticsMixin.methods.trackOnboardingNextStep;
const trackOnboardingPreviousStep = onbardingAnalyticsMixin.methods.trackOnboardingPreviousStep;

const onOpenStateChange = () => {
  if (onboardingModalOpen.value) {
    trackOnboardingOpen();
  }
};

const onCloseModal = ({ detail }) => {
  window.localStorage.setItem("onboardingWatched", "1");

  closeOnboardingModal();

  trackOnboardingClose(detail.activeStep, detail.numberOfSteps);
};

watch(() => onboardingModalOpen.value, onOpenStateChange);
</script>
