<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from "vue";

import { useDebounceFn } from "@vueuse/core";
import { logError } from "@telia/b2x-logging";
import { corpAddressLookup } from "@telia/b2b-rest-client";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { translateSetup, translateMixin } from "../locales";

type SearchResponse = {
  pointId: string;
  streetName: string;
  streetNumber: string;
  entrance: string;
  postalCode: string;
  city: string;
  fullAddress: string;
};

const MINIMUM_INPUT_LENGTH_FOR_SEARCH = 4;
const EMPTY_INPUT_LENGTH = 0;
const SEARCH_DELAY_IN_MS = 500;

const props = defineProps<{ scopeId: string }>();
const emit = defineEmits<{
  (e: "addressSubmit", address: SearchResponse): void;
  (e: "coordinateSubmit", coordinates: { x: string; y: string }): void;
  (e: "refreshTheLayout"): void;
}>();

const t = translateMixin.methods.t;
const addressSuggestions = ref();
const coordinateSearch = ref();
const yCoordinate = ref();
const xCoordinate = ref();
const addressError = ref("");
const suggestions = ref<SearchResponse[]>([]);
const address = ref<SearchResponse | undefined>();
const selectedAddress = ref<string | null>(null);
const coordinates = ref({
  x: "",
  y: "",
});
const loading = ref(false);
const initiatedSearch = ref(false);

onBeforeMount(() => {
  translateSetup();
});

onMounted(() => {
  addressSuggestions.value.addEventListener("inputValue", (event: CustomEvent) => {
    handleInput(event.detail);
  });

  addressSuggestions.value.addEventListener("suggestionSelected", (event: CustomEvent) => {
    handleSuggestionSelect(event.detail);
  });

  xCoordinate.value.addEventListener(
    "input",
    () => {
      trackEventsAddressMaster(analytics.action.INITIATED, "Sök X-koordinat");
    },
    { once: true }
  );

  yCoordinate.value.addEventListener(
    "input",
    () => {
      if (coordinates.value.x !== "") {
        trackEventsAddressMaster(analytics.action.COMPLETED, "Sök X-koordinat");
      }
      trackEventsAddressMaster(analytics.action.INITIATED, "Sök Y-koordinat");
    },
    { once: true }
  );

  new MutationObserver(() => {
    emit("refreshTheLayout");
  }).observe(coordinateSearch.value, {
    subtree: true,
    attributes: true,
  });
});

const parsedAddressSuggestions = computed(() => {
  return suggestions.value
    ? suggestions.value.map(function (address) {
        return address["fullAddress"];
      })
    : [];
});

const handleInput = useDebounceFn(async (input: string) => {
  selectedAddress.value = null;

  if (!initiatedSearch.value && input.length > 0) {
    trackEventsAddressMaster(analytics.action.INITIATED, "Sök Adress");
    initiatedSearch.value = true;
  }

  if (input.length < MINIMUM_INPUT_LENGTH_FOR_SEARCH) {
    if (input.length === EMPTY_INPUT_LENGTH) {
      suggestions.value = [];
      addressError.value = "";
    }
    return;
  }

  loading.value = true;
  if (!suggestionsIncludesInput(input, suggestions.value)) {
    try {
      const response = await getAddressSuggestions(input);
      if (response.length > 0) {
        const mappedResponse = response.map((suggestion) => ({
          pointId: suggestion.pointId ?? "",
          streetName: suggestion.streetName ?? "",
          streetNumber: suggestion.streetNumber ?? "",
          entrance: suggestion.entrance ?? "",
          postalCode: suggestion.postalCode ?? "",
          city: suggestion.city ?? "",
          fullAddress: suggestion.fullAddress ?? "",
        }));

        suggestions.value = mappedResponse.map((address: SearchResponse) => {
          let parsedStreetName = address.streetName
            .toLowerCase()
            .replace(/(^|[\s-])\S/g, (c) => c.toUpperCase());
          let parsedAddress = address.streetNumber
            ? `${parsedStreetName} ${address.streetNumber}, ${address.city}`
            : `${parsedStreetName}, ${address.city}`;

          return {
            ...address,
            fullAddress: parsedAddress,
          };
        });
      } else {
        addressError.value = t("AddressMaster.InvalidAddress");
      }
    } catch {
      addressError.value = t("AddressMaster.FetchError");
    }
    loading.value = false;
    emit("refreshTheLayout");
  }
}, SEARCH_DELAY_IN_MS);

const suggestionsIncludesInput = (input: string, suggestions: SearchResponse[]) => {
  if (input && suggestions.length > 0) {
    return Object.keys(suggestions)
      .map((key) => key.toLowerCase().substring(0, input.length))
      .includes(input.toLowerCase());
  }
  return null;
};

const getAddressSuggestions = async (input: string) => {
  try {
    return corpAddressLookup.AddressLookupControllerService.searchAddress(props.scopeId, input);
  } catch (e) {
    logError("b2b-dashboard", "Failed to get address suggestions in the DataNet module");

    throw new Error(e as string);
  }
};

const handleSuggestionSelect = (fullAddress: string) => {
  address.value = suggestions.value.find((el) => el.fullAddress === fullAddress);
  selectedAddress.value = address.value?.fullAddress ?? "";
  suggestions.value = [];
  addressError.value = "";

  trackEventsAddressMaster(analytics.action.COMPLETED, "Sök Adress");
  initiatedSearch.value = false;
};

const handleAddressSubmit = () => {
  addressError.value = "";
  if (!selectedAddress.value || !address.value) {
    addressError.value = t("AddressMaster.InvalidAddress");
    emit("refreshTheLayout");
    return;
  }
  trackEventsAddressMaster(analytics.action.CLICK, "Sök adress klickad");
  emit("addressSubmit", address.value);
};

const handleCoordinateSubmit = () => {
  emit("refreshTheLayout");
  if (coordinates.value.x.length !== 7 && coordinates.value.y.length !== 7) return;
  trackEventsAddressMaster(analytics.action.COMPLETED, "Sök Y-koordinat");
  trackEventsAddressMaster(analytics.action.CLICK, "Sök koordinater klickad");
  emit("coordinateSubmit", { x: coordinates.value.x, y: coordinates.value.y });
};

const trackEventsAddressMaster = (action: string, label: string) => {
  analytics.trackEvent(analytics.category.START_PAGE_MODULE, action, label);
};
</script>

<template>
  <div data-dont-collect class="address-master">
    <telia-tab variant="light-wide" @tabChange="emit('refreshTheLayout')">
      <telia-tab-content tab-id="1" :name="t('AddressMaster.AddressSearch')">
        <div class="address-search">
          <div class="address-input">
            <b2x-input-suggestions
              ref="addressSuggestions"
              :label="t('AddressMaster.Address')"
              :suggestions="JSON.stringify(parsedAddressSuggestions)"
              :loading="loading"
              :value="selectedAddress"
              :error="addressError"
            />
          </div>
          <div class="description-holder">
            <span v-html="t('AddressMaster.AddressSearchDescription')" />
          </div>
          <div class="button-holder">
            <telia-button t-id="address-button" variant="primary" @click="handleAddressSubmit()">{{
              t("AddressMaster.Continue")
            }}</telia-button>
          </div>
        </div>
      </telia-tab-content>

      <telia-tab-content tab-id="2" :name="t('AddressMaster.CoordinateSearch')">
        <div ref="coordinateSearch" class="coordinate-search">
          <form @submit.prevent>
            <div class="address-input">
              <telia-text-input
                ref="xCoordinate"
                :label="t('AddressMaster.Coordinates.x')"
                :value="coordinates.x"
                @input="coordinates.x = $event.target.value"
                minLength="7"
                maxLength="7"
                :minlength-error-message="t('AddressMaster.MinLength')"
                :additional="t('AddressMaster.SevenDigits')"
                required
                :required-error-message="t('AddressMaster.RequiredMessage')"
              />
            </div>
            <div class="address-input">
              <telia-text-input
                ref="yCoordinate"
                :label="t('AddressMaster.Coordinates.y')"
                :value="coordinates.y"
                @input="coordinates.y = $event.target.value"
                minLength="7"
                maxLength="7"
                :minlength-error-message="t('AddressMaster.MinLength')"
                :additional="t('AddressMaster.SevenDigits')"
                required
                :required-error-message="t('AddressMaster.RequiredMessage')"
              />
            </div>
            <div class="description-holder">
              <p>
                {{ t("AddressMaster.coordinatesDescriptionFirstPart") }}
                <b2x-tooltip class="tooltip" :content="t('AddressMaster.tooltip')">
                  (RT90)
                </b2x-tooltip>
                {{ t("AddressMaster.coordinatesDescriptionSecondPart") }}
              </p>
            </div>
            <div class="button-holder">
              <telia-button
                t-id="coordinate-button"
                type="submit"
                variant="primary"
                @click="handleCoordinateSubmit()"
                >{{ t("AddressMaster.Continue") }}</telia-button
              >
            </div>
          </form>
        </div>
      </telia-tab-content>
    </telia-tab>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";
.description-holder {
  margin-top: $telia-spacing-24;
  margin-bottom: $telia-spacing-32;
}
.button-holder {
  margin-top: $telia-spacing-12;
}
.address-input {
  :deep(.telia-tab__container) {
    padding: $telia-spacing-0;
  }
}

.tooltip {
  position: relative;
  color: #990ae3;
}

.tooltip::before {
  content: "";
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent var(--core-purple) transparent;
}

.tooltip::after {
  content: attr(content);
  position: absolute;
  z-index: 100;
  top: calc(100% + 1.6rem);
  left: -15rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 35rem;
  box-shadow: 0 4px 4px 1px var(--grey);
  padding: var(--spacing);
  border: 1px solid $telia-gray-200;
  border-top: 2px solid var(--core-purple);
  background-color: var(--white);
  color: var(--black);
  pointer-events: none;
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
}
</style>
