<script setup lang="ts">
import { nextTick, onBeforeMount, ref } from "vue";
import { logError } from "@telia/b2x-logging";
import { navigateToSearchPage, analytics as searchAnalytics } from "@telia/b2b-search-framework";
import { getLoggedInUserInfo, isTeliaAdmin } from "@telia/b2b-logged-in-service";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

import { translateSetup, translateMixin } from "./locales";
import { usePage } from "./composables/page";

import OnboardingModal from "./components/Onboarding/OnboardingModal.vue";
import Dashboard from "./components/Dashboard/Dashboard.vue";
import { useOrganisations } from "./composables/organisations";
import { useScope } from "./composables/scope";
import { FeatureFlags } from "./feature-flags/featureFlags";
import { useFlag } from "@unleash/proxy-client-vue";

onBeforeMount(async () => {
  await getScopeDetails();
  await fetchOrganisations();
  await translateSetup(["mybusiness"]);
  try {
    await getUserName();
    await nextTick();
    loadingTranslations.value = false;

    trackDashboardView();
  } catch {
    logError("b2b-dashboard", "Failed to get user name in the b2b-dashboard component");
  }

  document.title = t("DOCUMENT_TITLE");
});

const hideSearchbarEnabled = useFlag(FeatureFlags.SEARCH_DRAWER);
const { getScopeDetails, scopeId } = useScope();
const { fetchOrganisations } = useOrganisations();
const { openOnboardingModal } = usePage();
const loadingTranslations = ref(true);
const loadingUserName = ref(true);
const userName = ref("");
const t = translateMixin.methods.t;

const getUserName = async () => {
  try {
    if (await isTeliaAdmin()) {
      userName.value = "";
    } else {
      userName.value = (await getLoggedInUserInfo())?.firstName;
    }
  } catch {
    logError("b2b-dashboard", "Failed to get logged in user info in the b2b-dashboard component");

    userName.value = "";
  } finally {
    loadingUserName.value = false;
  }
};

const getGreeting = () => {
  const date = new Date();
  const day = date.getDay();
  const hour = date.getHours();
  let greetingPhrase = "";
  if ((day === 5 && hour >= 15) || day > 5) {
    greetingPhrase = t("GREETING.WEEKEND");
  } else if (hour < 3 || hour >= 18) greetingPhrase = t("GREETING.EVENING");
  else if (hour < 10) greetingPhrase = t("GREETING.MORNING");
  else if (hour < 15) greetingPhrase = t("GREETING.DAY");
  else if (hour < 18) greetingPhrase = t("GREETING.AFTERNOON");

  return userName.value && userName.value.length
    ? `${greetingPhrase} ${userName.value}!`
    : `${greetingPhrase}!`;
};

const trackDashboardView = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE,
    analytics.action.LANDED,
    `Användaren har landat på sidan`
  );
};

const trackAndNavigateToSearchPage = async (event: CustomEvent) => {
  await searchAnalytics.trackSubmitValidSearch(event.detail.text.length, "dashboard");
  navigateToSearchPage(event.detail.text);
};

const trackSearchFailedValidation = (event: CustomEvent) => {
  searchAnalytics.trackSubmitTooShortSearch(event.detail.text.length, "dashboard");
};
</script>

<template>
  <div>
    <div t-id="b2b-dashboard" class="b2b-dashboard">
      <OnboardingModal />

      <header class="b2b-dashboard__head">
        <div v-if="!loadingUserName" t-id="greeting-wrapper" class="b2b-dashboard__greeting">
          <telia-heading t-id="greeting" variant="title-200" tag="h1">
            {{ getGreeting() }}
          </telia-heading>
          <telia-button
            class="b2b-dashboard__onboarding-button"
            @click="openOnboardingModal"
            variant="tertiary-purple"
            size="sm"
          >
            {{ t("ONBOARDING_TEXT") }}&nbsp;
            <span class="b2b-dashboard__onboarding-text--underlined">
              {{ t("ONBOARDING_LINK") }}
            </span>
            <telia-icon slot="right" name="getting-started" size="sm" />
          </telia-button>
        </div>
        <div v-else t-id="greeting-skeleton" class="b2b-dashboard__greeting">
          <telia-skeleton class="b2b-dashboard__greeting-skeleton--heading"></telia-skeleton>
          <telia-skeleton class="b2b-dashboard__greeting-skeleton--paragraph"></telia-skeleton>
        </div>
        <b2b-search-form
          v-if="!hideSearchbarEnabled"
          @submitSearch="(event: CustomEvent) => trackAndNavigateToSearchPage(event)"
          @failedValidation="(event: CustomEvent) => trackSearchFailedValidation(event)"
          :loading="loadingTranslations ? 'true' : 'false'"
          :input-label="t('mybusiness.form.label.search.subscriptions')"
          :button-label="t('mybusiness.button.search.subscriptions')"
          :validation-error-message="t('mybusiness.form.error.subscriptionSearchTextTooShort')"
          auto-focus="false"
        />
      </header>
      <Dashboard />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "styles/voca";

.b2b-dashboard {
  @include telia-bg-color("gray-50");
  padding: $telia-spacing-20 0 5rem;
  min-height: calc(100vh - 270px);

  &__head {
    position: relative;
    @include telia-grid;
    padding-top: $telia-spacing-12 !important; // important because it's overriden by telia-grid
  }

  &__greeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__greeting-skeleton--heading {
    width: 30rem;
    height: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    @include telia-breakpoint("lg") {
      height: 2.5rem;
    }
  }

  &__onboarding-button {
    margin-top: $telia-spacing-2;
  }

  &__greeting-skeleton--paragraph {
    width: 28rem;
    height: 1.8rem;
    margin-top: 0.6rem;
    margin-bottom: $telia-spacing-8;
  }

  &__onboarding-text--underlined {
    text-decoration: underline;
    text-underline-offset: 0.1rem;
  }
}
</style>
