<script setup lang="ts">
import { onMounted } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";

const props = defineProps<{
  teaser: {
    id: string;
    title: string;
    image: {
      altText: string;
      url: string;
    };
    richPreamble: string;
  };
  heading?: string;
}>();

onMounted(() => {
  analytics.trackEventWithoutPrefix(
    analytics.category.RECOMMENDATIONS,
    analytics.action.VIEW,
    analytics.label.SHOW_RECOMMENDATION,
    null,
    [
      {
        type: analytics.customDimensions.PROMO_ID,
        value: props.teaser.id,
      },
      {
        type: analytics.customDimensions.PROMO_NAME,
        value: analytics.category.RECOMMENDATIONS,
      },
      {
        type: analytics.customDimensions.PROMO_CREATIVE,
        value: props.teaser.title,
      },
      {
        type: analytics.customDimensions.PROMO_POSITION,
        value: "1",
      },
    ]
  );
});
</script>

<template>
  <div class="container">
    <telia-p v-if="heading" class="heading">{{ heading }}</telia-p>
    <div class="wrapper">
      <div class="image-wrapper">
        <img
          class="image"
          :src="`https://www.telia.se/${teaser.image.url}`"
          :alt="teaser.image.altText"
        />
      </div>
      <div class="content">
        <telia-heading tag="h3" variant="title-200" class="title">
          {{ teaser.title }}
        </telia-heading>
        <telia-p class="preamble">{{ teaser.richPreamble }}</telia-p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../../styles/voca";

.heading {
  margin-bottom: $telia-spacing-12;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.image-wrapper {
  position: relative;
  width: 100%;
  max-height: 20rem;
  overflow: hidden;
}

.image {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.content {
  padding: $telia-spacing-16;
  width: 100%;
}

.title {
  margin-bottom: $telia-spacing-16;
}

.preamble {
  white-space: pre-line;
}
</style>
