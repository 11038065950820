<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { logError } from "@telia/b2x-logging";

import STATE from "../../state.enum";
import { translateSetup, translateMixin } from "./locales";
import * as errandsApi from "./errands.api";
import { useModule } from "../../../../composables/module";

import ListItem from "../../../shared/ListItem.vue";
import { trackNavigationInteraction } from "../../../../utils/trackGA4";
type CaseBasicResponse = {
  caseId: string;
  shortDescription: string;
  status: CaseBasicResponseStatus;
  createdOn: string;
  updatedOn: string;
};

type CaseBasicResponseStatus =
  | "NEW"
  | "OPEN"
  | "AWAITING_INFO"
  | "RESOLVED"
  | "CLOSED"
  | "CANCELLED"
  | "UNRECOGNIZED";

const t = translateMixin.methods.t;

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  moduleLoader,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

onBeforeMount(() => {
  translateSetup(props.title, props.name);
  getErrands();
});

const errands = ref<CaseBasicResponse[]>([]);
const skeletonErrandsCount = ref(2);

const shouldDisableFooter = computed(() => {
  return status.value === STATE.LOADING || status.value === STATE.ERROR;
});

const getErrands = async () => {
  status.value = STATE.LOADING;

  if (!hasSelectedOrganisation.value) {
    status.value = STATE.PRECONDITION_NOT_MET;
    return;
  }

  await errandsApi
    .getErrands(props.scopeId, [props.selectedOrganisation])
    .then((response) => {
      errands.value =
        response.cases?.map((caseBasic) => ({
          caseId: caseBasic.caseId ?? "",
          shortDescription: caseBasic.shortDescription ?? "",
          createdOn: caseBasic.createdOn ?? "",
          updatedOn: caseBasic.updatedOn ?? "",
          status: caseBasic.status ?? "UNRECOGNIZED",
        })) ?? [];
      status.value = errands.value?.length ? STATE.SUCCESS : STATE.NO_ITEMS;
      skeletonErrandsCount.value = Math.max(errands.value?.length, 1);
    })
    .catch(() => {
      logError("b2b-dashboard", "Failed to get customer errands in the errands module");

      status.value = STATE.ERROR;
    });

  refreshTheLayout();
};

const extractDate = (dateTime: string) => {
  return dateTime.slice(0, 10);
};

const getStatusText = (status: CaseBasicResponseStatus) => {
  switch (status.toLowerCase()) {
    case "new":
    case "open":
      return t("ERRANDS.STATUS.OPEN");
    case "awaiting_info":
      return t("ERRANDS.STATUS.AWAITING_INFO");
    case "resolved":
      return t("ERRANDS.STATUS.RESOLVED");
    default:
      return t("ERRANDS.STATUS.UNKNOWN");
  }
};

const getStatusVariant = (status: CaseBasicResponseStatus) => {
  switch (status.toLowerCase()) {
    case "new":
    case "open":
      return "information";
    case "awaiting_info":
      return "caution";
    case "resolved":
      return "positive";
    default:
      return "neutral";
  }
};

const trackEventErrandsClicked = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "Ärende klickat"
  );
};

watch(() => props.selectedOrganisation, getErrands);
</script>

<template>
  <Module
    id="Errands"
    :title="t('ERRANDS.MODULE_TITLE')"
    :link-title="t('ERRANDS.LINK_ALL_ERRANDS')"
    link-href="/foretag/mybusiness/support/arenden"
    :footerDisabled="shouldDisableFooter"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :loading="isLoading"
    @module-removed="emit('module-removed', $event)"
  >
    <div class="errands-container" ref="moduleContainer">
      <div v-if="isLoading" ref="moduleLoader" key="moduleLoader">
        <div v-for="index in skeletonErrandsCount" :key="index" class="errands__skeleton-wrapper">
          <telia-skeleton class="errands__skeleton errands__skeleton--title"></telia-skeleton>
          <telia-skeleton class="errands__skeleton errands__skeleton--status"></telia-skeleton>
        </div>
      </div>

      <div class="errands__notification">
        <telia-notification
          v-if="status === STATE.ERROR"
          heading-tag="h4"
          variant="inline"
          status="warning"
          html-aria-live="polite"
          html-role="alert"
        >
          <span slot="heading">
            <telia-visually-hidden>{{ t("ERRANDS.ERROR.A11Y_STATUS") }}</telia-visually-hidden>
            {{ t("ERRANDS.ERROR.TITLE") }}
          </span>
        </telia-notification>
      </div>

      <module-empty-state v-if="status === STATE.NO_ITEMS">
        {{ t("ERRANDS.NO_ITEMS") }}
      </module-empty-state>

      <div class="errands-list" v-if="status === STATE.SUCCESS">
        <ListItem
          v-for="errand in errands"
          :key="errand.caseId"
          :href="`/foretag/mybusiness/support/arenden/detaljer/${errand.caseId}`"
          :title="errand.shortDescription"
          :right-title="extractDate(errand.createdOn)"
          ga-text="Errands details"
          @click="trackEventErrandsClicked()"
        >
          <telia-status-badge
            class="errands__status-badge"
            :variant="getStatusVariant(errand.status)"
          >
            <div class="errand-description">{{ getStatusText(errand.status) }}</div>
          </telia-status-badge>
        </ListItem>
      </div>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.errands-container {
  min-height: 7.2rem;
}

:deep(.errands-list) {
  .list-item__title {
    margin-bottom: $telia-spacing-4;
  }
}

.errands {
  &__skeleton-wrapper {
    padding: $telia-spacing-12 0;

    &:not(:last-child) {
      @include telia-border("gray-200", "xs", "bottom");
    }
  }

  &__skeleton {
    height: $telia-spacing-20;

    &--title {
      width: 100%;
    }

    &--status {
      width: $telia-spacing-80;
      margin-top: $telia-spacing-8;
    }
  }
}
</style>
