export function numberToWord(number: number, language: string): string | number {
  if (number > 12) return number;

  const numbersSv = [
    "en",
    "två",
    "tre",
    "fyra",
    "fem",
    "sex",
    "sju",
    "åtta",
    "nio",
    "tio",
    "elva",
    "tolv",
  ];
  const numbersEn = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
  ];

  if (language === "en") {
    return numbersEn[number - 1];
  } else {
    return numbersSv[number - 1];
  }
}
